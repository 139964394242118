@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800");

@font-face {
  font-family: "GT-Walsheim-Medium";
  src: url("../fonts/GT-Walsheim-Medium.eot");
  src: url("../fonts/GT-Walsheim-Medium.woff2") format("woff2"), url("../fonts/GT-Walsheim-Medium.woff") format("woff"),
    url("../fonts/GT-Walsheim-Medium.ttf") format("truetype"),
    url("../fonts/GT-Walsheim-Medium.svg#GTWalsheimProTrial-Regular") format("svg"),
    url("../fonts/GT-Walsheim-Medium.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "GT-Walsheim-Medium-Oblique";
  src: url("../fonts/GT-Walsheim-Medium-Oblique.eot");
  src: url("../fonts/GT-Walsheim-Medium-Oblique.woff2") format("woff2"),
    url("../fonts/GT-Walsheim-Medium-Oblique.woff") format("woff"),
    url("../fonts/GT-Walsheim-Medium-Oblique.ttf") format("truetype"),
    url("../fonts/GT-Walsheim-Medium-Oblique.svg#GTWalsheimProTrial-Regular") format("svg"),
    url("../fonts/GT-Walsheim-Medium-Oblique.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal;
}

$site-min-width: 990px;

@import "colors";

@mixin material-ui-icon-stroke($color) {
  path:first-child {
    stroke: $color;
    stroke-linejoin: bevel;
  }
}

@mixin rounded-arrow($color) {
  fill: $color;

  path:first-child {
    stroke-width: 1.5px;
  }

  @include material-ui-icon-stroke($color);
}

@mixin chip-item($background-color, $icon-color) {
  display: inline-flex;
  background-color: $background-color;
  margin: 0 5px 5px 0;
  color: $dark-gray;

  .delete-icon {
    @include material-ui-icon-stroke($icon-color);
    height: 12px;
    fill: $icon-color;

    path:first-child {
      stroke-width: 2px;
    }

    &:hover {
      @include material-ui-icon-stroke($gray);
      fill: $gray;
    }
  }
}

@mixin hover-opacity($opacity, $animationDuration: 0.1s) {
  opacity: 1;
  transition: opacity $animationDuration;

  &:hover,
  &:active {
    opacity: $opacity;
  }
}

@mixin status-icon($color) {
  background: $color !important;
  color: $white !important;
  border-radius: 11px !important;
  font-size: 11px !important;
  line-height: 13px !important;
  font-weight: 700 !important;
  max-width: 150px;

  &.place-top {
    &:after {
      border-top-color: $color !important;
    }
  }

  &.place-left {
    &:after {
      border-left-color: $color !important;
    }
  }

  &.place-bottom {
    &:after {
      border-bottom-color: $color !important;
    }
  }

  &.place-right {
    &:after {
      border-right-color: $color !important;
    }
  }
}

@mixin default-box-shadow() {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

.default-box-shadow {
  @include default-box-shadow();
}

#app {
  overflow: hidden;
}

*,
input {
  font-family: "Open Sans";

  &:focus {
    outline: none;
  }
}

.full-width {
  width: 100%;
}

.horizontal-scroll {
  overflow-x: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.font-light {
  font-weight: 300;
}

.font-regular {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.general-text {
  font-weight: 600;
  color: $gray;
  font-size: 13px;
}

body {
  font-family: "Open Sans", sans-serif;
  min-width: $site-min-width;

  h1 {
    font-size: 30px;
  }

  background-color: $white;
}

@media screen and (max-width: $site-min-width) {
  body {
    overflow-x: scroll;
  }
}

@mixin title-font() {
  font-family: "GT-Walsheim-Medium";

  &.oblique {
    font-family: "GT-Walsheim-Medium-Oblique";
  }
}

.title-font {
  @include title-font();
}

@mixin clickable() {
  cursor: pointer;
}

@mixin default-cursor() {
  cursor: default;
}

.clickable {
  @include clickable();
}

.capitalized-text {
  text-transform: capitalize;
}

.link {
  @include clickable();
  color: $blue;

  &:hover {
    text-decoration: underline;
  }
}

.fake-link {
  &,
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    color: inherit;
  }
}

.default-cursor {
  @include default-cursor();
}

.loading {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

// default button styling
button.round-button {
  @include clickable();
  font-weight: 600;
  padding: 0 20px;
  border-radius: 15px;
  color: $white;
  border: none;
  font-size: 14px;
  background: #000000; // this is just a default if we forget the color class
  text-transform: none;
  min-height: 30px;
  line-height: 30px;
  box-shadow: none;

  &:disabled,
  &.disabled {
    opacity: 0.5;
  }
}

button.flat-button {
  text-transform: initial;
  color: $gray;

  &:hover {
    background-color: inherit;
    color: $light-gray;
  }

  &:disabled {
    opacity: 0.5;
  }
}

button {
  &:focus {
    outline: none;
  }

  &.red {
    background: $red;

    &:hover {
      background: lighten($red, 10);
    }

    &:focus {
      background: darken($red, 10);
    }
  }

  &.green {
    background: $green;

    &:hover {
      background: $green-hover;
    }

    &:focus {
      background: $green-hover;
    }
  }

  &.blue {
    background: $blue;

    &:hover {
      background: lighten($blue, 10);
    }

    &:focus {
      background: darken($blue, 10);
    }
  }

  &.blue-black {
    background: $blue;

    &:hover {
      background: lighten($blue, 10);
    }

    &:focus {
      background: darken($blue, 10);
    }

    svg {
      fill: black;
    }
  }

  &.gray {
    font-weight: 600;
    background-color: #f6f5f5;
    color: $light-gray;

    &:hover {
      background: $gray-button-hover;
    }

    &:focus {
      background: $gray-button-hover;
    }
  }
}

.default-menu {
  @include default-box-shadow();
  border-radius: 6px;
  min-width: 136px;

  li,
  a {
    font-weight: 500;
    font-size: 13px;
    padding-top: 2px;
    padding-bottom: 2px;
  }

  a {
    text-decoration: none;
    display: flex;
    align-items: center;
    color: $dark-gray;
    height: 28px;
    padding: 2px 16px;
  }

  .menu-item-placeholder {
    height: 0;
    padding: 0;
  }
}

.default-menu-item {
  &:hover,
  &:focus {
    background-color: #f6f5f5;
  }
}

.target-group-action-link {
  padding-left: 15px;

  &:hover {
    text-decoration: underline;
  }
}
div.group-options-menu {
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  font-size: 13px;
  color: #999;
  font-weight: 400;
}

div.tooltip-custom {
  &.__react_component_tooltip {
    font-weight: 400;
    background: $white;
    color: $gray;
    font-size: 12px;
    line-height: 16px;
    border-radius: 6px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.14);
    padding: 6px 13px;
    max-width: 240px;
    z-index: 9999;
    margin-top: -12px;

    opacity: 0;
    transition: margin-top 0.15s ease-in-out, visibility 0.2s, opacity 0.15s;

    &.show {
      margin-top: -5px;
      opacity: 1;
    }

    &.type-info {
      &.place-top {
        &:after {
          border-top-color: $white;
        }
      }

      &.place-left {
        &:after {
          border-left-color: $white;
        }
      }

      &.place-bottom {
        &:after {
          border-bottom-color: $white;
        }
      }

      &.place-right {
        &:after {
          border-right-color: $white;
        }
      }

      &.campaign-field-tooltip,
      &.table-header-tooltip {
        padding: 15px;
        margin-top: -20px;
        opacity: 0;
        transition: margin-top 0.15s ease-in-out, visibility 0.2s, opacity 0.15s;

        &.show {
          margin-top: -10px;
          opacity: 1;
        }

        .new-field-container {
          width: 47px;
          height: 15px;
          border-radius: 8px;
          background-color: $sky-blue;
          color: white;
          font-size: 10px;
          font-weight: 600;
          background-image: url("../images/StarIcon.png");
          background-repeat: no-repeat;
          background-position: 6px center;
          display: flex;
          justify-content: flex-end;
          padding: 0 7px;
          margin-bottom: 8px;
        }
      }

      &.nowrap {
        white-space: nowrap;
        max-width: 100%;
      }

      &.tags-tooltip {
        padding: 6px 10px;

        &.show {
          margin-top: 2px;
          opacity: 1;
        }
      }
    }

    strong {
      font-weight: 700;
    }

    &.message-expired {
      @include status-icon($orange);
      padding: 5px 13px;
    }

    &.type-running {
      @include status-icon($green);
      padding: 4px 13px 6px 13px;

      &.orange {
        @include status-icon($orange);
      }
    }

    &.type-capped {
      @include status-icon($capped-color);
      padding: 5px 13px;
    }

    &.type-inactive {
      @include status-icon($inactive-color);
      padding: 5px 13px;
    }

    &.type-rejected {
      @include status-icon($red);
      padding: 5px 13px;
    }

    &.type-pending {
      @include status-icon($light-gray);
    }

    &.type-admin {
      @include status-icon($admin-gray);
    }

    &.type-paused {
      @include status-icon($light-gray);
      padding: 5px 13px;

      &.orange {
        @include status-icon($orange);
      }
    }

    &.type-icon-info {
      @include status-icon($dark-tooltip-gray);
      font-weight: 600;
      padding: 4px 13px;
      font-size: 11px;
      max-width: 300px;

      &.note-tooltip {
        margin-left: 12px;

        &:after {
          display: none;
        }
      }
    }

    &.tooltip-text-align-center {
      text-align: center;
    }

    &.create-campaign {
      border-radius: 14px;
      font-weight: 600;
    }

    &.shrink-tip {
      &:after {
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        bottom: -4px;
        margin-left: -6px;
      }
    }

    &.filter {
      opacity: 0;
      margin-top: -12px;
      transition: margin-top 0.15s ease-in-out, visibility 0.2s, opacity 0.15s;

      &.show {
        margin-top: -6px;
        opacity: 1;
      }
    }

    &.campaign-search,
    &.collection-search-tooltip {
      opacity: 0;
      margin-top: -4px;
      transition: margin-top 0.15s ease-in-out, visibility 0.2s, opacity 0.15s;

      &.show {
        margin-top: 2px;
        opacity: 1;
      }
    }

    &.country-group-tooltip {
      padding: 16px 14px;
      font-size: 13px;
      line-height: 22px;
      pointer-events: auto;

      > div {
        font-weight: 600;
        margin-bottom: 8px;
      }

      > a {
        color: #147ae4;
        cursor: pointer;
        font-weight: 400;
        padding-top: 10px;
        text-decoration: none;
      }
    }

    &.wide {
      max-width: 400px;
      white-space: normal;
      word-wrap: break-word;
    }
  }
}

.notification {
  font-weight: 600;
  display: flex;
  align-items: center;
  height: 46px;
  min-width: 510px;
  background-color: white;
  border: 1px solid gainsboro;
  border-radius: 23px;
  box-shadow: 0 4px 9px rgba(0, 0, 0, 0.12);
  color: $red;
  font-size: 15px;
  padding: 0 20px;
  margin: 0 auto;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1400;

  &.error {
    color: $red;
  }

  &.app-refresh {
    color: $gray;
  }

  &.campaigns-refresh,
  &.campaignsV2-refresh {
    color: $gray;
    z-index: 1200; // Be under popovers
  }

  &.campaigns-refresh,
  &.campaignsV2-refresh,
  &.campaigns-bulk-status-change-error,
  &.campaigns-bulk-status-change-warning {
    .action {
      margin-left: 10px;
    }
  }

  &.campaigns-bulk-status-change-warning {
    color: $orange;
  }

  .notification-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .message {
      padding-right: 20px;
    }
  }

  .action {
    @include clickable();
    display: flex;
    align-items: center;
    color: #b5b5b5;
    font-size: 13px;
    padding-top: 2px;
  }

  .action-link {
    @include clickable();
    display: flex;
    align-items: center;
    color: $blue;
    font-size: 13px;
    padding-top: 2px;
  }

  .refresh {
    @include clickable();
    display: flex;
    align-items: center;
    justify-content: center;
    width: 107px;
    height: 31px;
    background-color: $blue;
    border-radius: 15px;
    font-size: 14px;
    color: $white;
    font-weight: 600;

    > svg {
      margin-right: 4px;
    }
  }

  .icon {
    margin-right: 16px;
  }
}

span.switch {
  width: 45px;
  height: 22px;

  span.button-base {
    height: 100%;
    width: 100%;
    transform: translateX(0);
    position: relative;

    &:before {
      font-weight: 600;
      content: "OFF";
      color: $white;
      font-size: 8px;
      position: absolute;
      left: 24px;
    }

    & + .bar {
      background-color: #bfbfbf;
      border-radius: 11px;
      width: 100%;
      height: 100%;
      opacity: 1;
      margin: 0;
      left: 0;
      top: 0;
    }

    .icon {
      background-color: $white;
      width: 18px;
      height: 18px;
      box-shadow: none;
      position: absolute;
      left: 2px;
      transition: left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }

    &.green {
      &:before {
        content: "ON";
        right: auto;
        left: 8px;
      }

      & + .bar {
        background-color: $green;
      }

      .icon {
        left: calc(100% - 20px);
      }
    }
  }
}

div.loader {
  z-index: 1000;
  opacity: 1;
}

.play-icon {
  &.green {
    .play-icon-svg {
      fill: $green;
    }
  }

  &.orange {
    .play-icon-svg {
      fill: $orange;
    }
  }

  &.red {
    .play-icon-svg {
      fill: $red;
    }
  }

  &.inactive {
    .play-icon-svg {
      fill: $inactive-color;
    }
  }
}

.pause-icon {
  .pause-icon-svg {
    fill: $light-gray;
  }
  &.orange {
    .pause-icon-svg {
      fill: $orange;
    }
  }
  &.red {
    .pause-icon-svg {
      fill: $red;
    }
  }
}

.play-icon-scheduled {
  opacity: 0.5;
}

.rejected-icon {
  .inactive-icon-svg {
    fill: $red;
  }
}

svg {
  &.clear-filter-text {
    @include clickable();
    @include material-ui-icon-stroke(#d1d1d1);
    display: flex;
    height: 14px;
    fill: #d1d1d1;
    align-items: center;
    justify-content: flex-end;
    margin-top: 0;
    border-radius: 1px;
  }
}

div.text-field {
  > div {
    &:before,
    &:after {
      content: none;
    }
  }

  div.small-text {
    font-size: 14px;
  }
}

ul {
  margin: 0;
}

.red {
  color: $red;
}

.orange {
  color: $orange;
}

.green {
  color: $green;
}

.page-loading {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 579px;
}

.export-button {
  font-weight: 600;
  height: 30px;
  background-color: whitesmoke;
  border-radius: 15px;
  color: $gray;
  font-size: 13px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  border: none;
  position: relative;

  .icon {
    margin-left: 5px;
  }

  .loader {
    line-height: 7px;
  }
}

// These classes were made in order to align the main grid
// All other alignments should be made using Bootstrap grid
.main-body-1 {
  width: calc(100% - 120px);
}

.main-margins-1 {
  width: 60px;
}

.general-drawer-item {
  @extend .general-text;
  font-weight: 500;
  border: 1px solid $border-gray;
  font-size: 11px;
  border-top: none;
  min-height: 76px;
  padding: 16px 10px;
  display: flex;
  flex-direction: row;
  white-space: pre-line;

  .right {
    display: inline-flex;
    position: relative;
    top: 6px;
    left: 16px;
    width: 4%;
    vertical-align: top;

    > div {
      width: 100%;
    }
  }

  .left {
    display: inline-block;
    width: 96%;
  }

  .parameter {
    @include clickable();
    font-weight: 600;
    margin: 0 2px;
    color: $blue;

    &.disabled {
      cursor: default;
      color: inherit;
    }
  }
}

.close-icon {
  @include material-ui-icon-stroke($silver-gray);
  fill: $silver-gray;
  position: absolute;

  &.top-right {
    top: 20px;
    right: 20px;
  }
}

#app {
  min-height: 100vh;
  margin: 0;

  .app-content {
    min-height: 100vh;

    &.disable-mode {
      pointer-events: none;
      opacity: 0.4;
    }

    footer {
      padding: 20px;
      padding-bottom: 50px;
    }
  }
}
.articles,
.unintegrated,
.drafts {
  width: 100%;
}
.dashboard-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 100px 300px;
}

.dashboard {
  margin-bottom: 40px;
  .campaign-popup-chart {
    input[type="checkbox"] {
      + span.custom-checkbox-style {
        position: absolute;
        width: 14px;
        height: 14px;
        display: flex;
        border-radius: 2px;
        pointer-events: none;
        font-weight: 300;
        font-size: 8px;
        color: white;
        align-items: flex-start;
        justify-content: center;
        padding-top: 1px;
      }

      &:not(:checked) {
        + span.custom-checkbox-style {
          background-color: white !important;
          border: 2px solid $gainsboro-gray !important;
        }
      }
    }
  }

  .performance-by-hour-chart,
  .performance-by-page-chart {
    .visit-value-tooltip {
      display: flex;
      flex-direction: column;

      .line {
        height: 16px;
        position: relative;
        left: 3px;
        border-left: 2px solid #268f4f;
        margin-top: -6px;
        margin-bottom: -8px;
      }

      .tooltip-row {
        .bullet {
          width: 6px;
          height: 6px;
          border: 2px solid green;
          border-radius: 3px;
          margin-right: 4px;
          margin-left: 1px;
        }
      }

      .limiter {
        height: 0;
        border-bottom: 1px solid $border-gray;
        margin: 5px 0;
      }
    }
  }

  .campaign-popup-list {
    margin-top: 40px;
    position: relative;
  }

  .campaign-popup-chart-wrapper {
    position: relative;

    .empty-chart-indicator {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: calc(100% - 170px);
      transform: translateY(-50%);
      position: absolute;
      color: $light-gray;
      font-size: 13px;
      z-index: 2;
      top: calc(50% - 8px);

      .chart-icon {
        width: 48px;
        height: 48px;
        display: flex;
        border-radius: 50%;
        margin-bottom: 10px;
        align-items: center;
        justify-content: center;
        background-color: $border-gray;

        .show-chart-icon {
          fill: $light-gray;
        }
      }
    }
  }
}

footer.in-app-footer {
  margin-top: 59px;
  > .row {
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: $light-gray;
    font-size: 12px;
    height: 59px;
    margin-top: -59px;
    background-color: whitesmoke;
  }

  .main-body-1 {
    display: flex;
  }

  .copyright {
    flex: 1;
  }

  .pages {
    a {
      margin-right: 20px;
      color: $light-gray;
    }
  }
}

.checkbox {
  color: $gainsboro-gray;
  height: 30px;
  width: 30px;
  padding: 0;

  &:not(.checked) {
    &:before {
      content: " ";
      top: 9px;
      left: 9px;
      right: 9px;
      bottom: 9px;
      background: white;
      position: absolute;
    }
  }

  &.checked {
    color: $sky-blue;
  }

  svg {
    font-size: 21px;
  }
}

.white-space-pre {
  white-space: pre;
}

div.last-modified-action {
  line-height: 10px;
  margin-bottom: -8px;

  .last-modified-action-text {
    font-size: 10px;
    color: $light-gray;
  }
}

ul.tags-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  color: $dark-gray;
  font-size: 12px;
  font-weight: 600;

  li {
    margin: 5px 0;
  }

  .more-tags {
    color: $blue;
  }
}

div.tags-container {
  display: flex;

  .static-tags {
    padding: 0;
    margin: 0;
  }

  .last-tags {
    padding: 0;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.status_tags_popup {
  display: flex;
  padding: 0;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.max-tag-length-error {
  color: $red;
  margin: -1px 0 3px 20px;
  font-size: 10px;
}

.tag-is-not-ascii-error {
  color: $red;
  margin: -1px 0 3px 20px;
  font-size: 10px;
}

.max-tags-per-campaign-error {
  color: $red;
  margin: 0 0 5px 20px;
  font-size: 10px;
  width: 171px;
}

.max-selected-tags-per-campaign-error {
  color: $red;
  margin: 0 0 5px 20px;
  font-size: 10px;
}

@import "components/datePicker";
@import "components/campaignCommonComponents";
@import "components/campaignsSearch";
@import "components/collectionSearch";
@import "components/loaderAnimation";
@import "components/reactSlider";
@import "components/autoSuggest";
@import "components/genericDropdown";
@import "components/genericAutosuggest";
@import "components/creativesList";
@import "components/durationPicker";
@import "components/campaignDelay";
@import "components/campaignLearning";
@import "components/jsonEditor";
@import "components/table";
@import "components/advancedSearch";
@import "components/wordpressAssets";
@import "campaignDetailsCell";
@import "pagination";
@import "filter";
@import "campaignsPage";
@import "reportPage";
@import "loginPage";
@import "navigationBar";
@import "campaignPopup";
@import "settings";
@import "drawer";
@import "campaignCreationWizard";
@import "admin";
@import "themes/dark";
@import "flags";
@import "profile";
@import "~react-image-crop/lib/ReactCrop";
@import "balloon";
@import "automationCreation";
@import "policyPage";
