// color defaults
$pinkish-white: #fef7f7;
$pinkish-pink: #fff2f3;
$white: #ffffff;
$light-pink: #fadfff;
$border-pink: #ffe5e7;
$border-red: #f1949b;
$red: #f22939;
$capped-color: #f12938;
$orange: #ff8f00;
$yellow: #fed835;
$gold: #d8b143;
$light-yellow: #fffbe6;
$border-yellow: #f2eecc;
$green: #0dcb5c;
$green-hover: #0bc056;
$light-blue: #c9e7ff;
$sky-blue: #42a5f5;
$blue: #2e92ea;
$purple: #ab47bc;
$admin-black: #181818;
$night-gray: #2c2c2c;
$dark-gray: #444444;
$zambezi-gray: #5a5a5a;
$inactive-color: #666766;
$gray: #666;
$dark-tooltip-gray: #777;
$light-gray: #999999;
$admin-gray: #a7a7a7;
$silver-gray: #bbbbbb;
$silver: #bebebe;
$border-gray: #efefef;
$gray-button-hover: #eeeeee;
$bg-gray: #fafafa;
$snow-gray: #f9f9f9;
$bluish-white: #eff8ff;
$ghost-white: #f7f6f6;
$gainsboro-gray: #e1e1e1;
$whisper-gray: #e6e6e6;
