.dashboard-report-totals-box {
  height: 180px;
  background-color: whitesmoke;
  border: 1px solid #ececec;
  border-radius: 6px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.04);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .value {
    font-weight: 700;
    color: $gray;
    font-size: 36px;
    line-height: 36px;
    margin-bottom: 10px;
  }

  .text {
    font-weight: 600;
    color: $gray;
    font-size: 15px;
    line-height: 15px;
  }

  .red {
    color: $red;
  }

  .orange {
    color: $orange;
  }

  .green {
    color: $green;
  }

  .down-arrow {
    fill: #d1d1d1;
    width: 16px;
    height: 16px;
    margin: 5px 0 0 3px;
    position: relative;
    top: 3px;

    > path:first-child {
      stroke: #d1d1d1;
      stroke-width: 1.5px;
      stroke-linejoin: bevel;
    }
  }
}

.last-update {
  @extend .general-text;
  font-weight: 400;
  color: $light-gray;
}

.last-update-icon {
  position: relative;
  top: 3px;
  width: 16px;
  height: 16px;
  margin-right: 4px;
}
