.generic-table {
  font-size: 0;
  width: 100%;
  overflow-x: auto;
  display: inline-block;

  .cell.last-cell-header {
    border-top-right-radius: 12px;
  }
  .cell.site-actions {
    .generic-dropdown-selection {
      border: none;
      margin-bottom: 0px;
    }
  }

  .sticky-header {
    position: static;
    top: 0;
    z-index: 1;

    &.fixed-header {
      position: fixed;

      .sticky-column {
        // Sticky columns don't work well with a position:fixed parent, so we will imitate that behavior using JS
        position: relative;
      }

      .cell {
        // Each cell in the fixed header is going to get a "right" value using JS
        position: relative;
      }

      // Cancelling rounded corners when the header is fixed
      &.header {
        .table-row:first-child {
          .cell:first-child {
            border-top-left-radius: 0;
          }

          .cell:last-child {
            border-top-right-radius: 0;
          }
        }
      }
    }
  }

  .sticky-column {
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    display: flex;
    height: 100%;
    z-index: 1;
    flex-grow: 10;
  }

  .campaign-name {
    flex-grow: 4;
    padding-left: 16px;
    text-align: left;

    > span {
      color: $dark-gray;
      display: inline-block;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: left;
    }
  }
  .funnel-column {
    padding: 5px;
    text-align: left;
  }

  .cell {
    flex-grow: 1;
    height: 60px;
    border-right: 1px solid $border-gray;
    border-bottom: 1px solid $border-gray;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-shrink: 0;
    background: $white;
    transition: background-color 0.3s;
    font-size: 100%;
    position: relative;
    overflow-wrap: anywhere;

    &:first-child {
      border-left: 1px solid $border-gray;
    }
    &.item-row {
      height: 100px;
      padding: 10px 10px;
      .image-cell {
        justify-content: center;
      }
    }
    &.article-height-row {
      height: 100px;
      padding: 10px 15px;

      .page-titles {
        // only show 2 lines of text
        text-align: left;
        font-size: 11px;
        overflow: scroll;
        color: $gray;
        display: block;
        width: 100%;
        height: 100%;
      }
      .article-details-cell {
        justify-content: flex-start;
        overflow: hidden;
        width: 100%;
        height: 100%;

        .article-image {
          width: 80px;
          height: 80px;
          min-width: 80px;
          background-position: 50%;
          background-size: cover;
          border-radius: 5px;
          justify-content: center;
          align-items: center;
          display: flex;
        }

        .article-details {
          flex-wrap: wrap;
          text-align: center;
          height: 100%;
          max-width: 90%;
          display: block;

          .content-text {
            min-height: 50px;
            max-height: 74%;
            text-align: left;
            margin-left: 10px;
            font-family: Arial, sans-serif;
            font-size: 14px;
            line-height: 20px;
            overflow: hidden;

            .article-name {
              color: #999;
              font-size: 13px;
              font-weight: 400;
              text-decoration: none;
              display: block;
            }
            .article-title {
              color: #333;
              overflow: hidden;
              text-overflow: ellipsis;
              min-height: 40px;
              max-height: 40px;
              display: block;
            }
            .post-title {
              color: #333;
              font-weight: 600;
              overflow: hidden;
            }
          }
          .link-block {
            color: #999;
            display: flex;
            align-items: center;
            margin-left: 10px;
            margin-top: 5px;
            .article-link {
              font-size: 10px;
              display: flex;
              align-items: center;
              text-decoration: none;
              .article-url {
                color: #999;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-left: 5px;
              }
            }
            .link-icon {
              width: 10px;
            }
          }
        }
      }
    }

    &.x-small {
      flex-grow: 0;
      width: 68px;
    }

    &.slimmer-small {
      flex-grow: 0.5;
      width: 80px;
    }

    &.small {
      flex-grow: 1;
      width: 93px;
    }

    &.small-medium {
      flex-grow: 1.1;
      width: 110px;
    }

    &.medium {
      flex-grow: 1.2;
      width: 130px;
    }

    &.medium-large {
      flex-grow: 2.2;
      width: 275px;
    }

    &.large {
      flex-grow: 3.5;
      width: 420px;
    }

    &.x-large {
      flex-grow: 5;
      width: 600px;
    }

    &.large-filler {
      width: 744px;

      .text {
        margin-left: 6px;
        font-weight: 700;
      }
    }

    &.not-available {
      color: $light-gray;
      font-size: 11px;
    }

    &.justify-left {
      padding-left: 24px;
      justify-content: left;
    }

    &.no-grow {
      flex-grow: 0;
    }

    &.aligned-left {
      justify-content: flex-start;
      padding-left: 10px;

      &.spread-content {
        justify-content: space-between;
        padding-right: 10px;
      }
    }

    &.conditions-rule {
      display: inline-flex;
      padding: 10px;
      margin: 0;
      flex-wrap: wrap;
      max-width: 100%;
      text-align: left !important;
      align-items: flex-start !important;
      flex-direction: column;
    }

    &.bulk-selection-cell {
      padding-left: 7px;
    }

    &.icon-right-margin {
      .flag {
        margin-right: 12px;
      }
    }

    &.date-cell {
      display: flex;
      flex-direction: column;
      padding-top: 11px;

      .week-day {
        font-weight: 300;
        font-size: 9px;
        color: #bcbcbc;
        margin-top: -1px;
        text-transform: uppercase;
      }
    }

    .cell-filler {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .campaign-roi {
      font-weight: 600;
    }

    .action-text {
      margin-left: 6px;
      font-weight: 700;
    }

    .automation-history-campaign-link {
      color: $gray;
    }

    .draft-review-status {
      &.red {
        color: $red;
        font-weight: 600;
      }

      &.green {
        color: $green;
        font-weight: 600;
      }

      &.orange {
        color: $orange;
        font-weight: 600;
      }

      &.dark-gray {
        color: $dark-gray;
        font-weight: 600;
      }
    }

    .draft-creation-status {
      display: flex;
      align-items: center;

      .failed-draft-creation-icon {
        margin-right: 1.8px;
        margin-top: 7px;
      }

      .failed {
        font-weight: 600;
        color: $red;
      }
    }
  }

  .header {
    min-width: 100%;
    min-height: 45px;

    .last-child {
      border-top-right-radius: 12px;
      border-right: none;
    }

    &.table-row,
    .table-row {
      height: 45px;
    }

    .table-row:hover {
      div.cell {
        background-color: whitesmoke;
      }
    }

    .table-row:first-child {
      .sticky-column {
        z-index: 2;

        .cell:first-child {
          border-top-left-radius: 12px;
          border-left: none;
        }
      }

      > div:first-child {
        &.cell {
          border-top-left-radius: 12px;
          border-left: none;
        }
      }

      > div:not(.sticky-column) {
        &.cell:last-child {
          border-top-right-radius: 12px;
          border-right: none;
        }
      }
    }

    .sort-icon {
      margin-left: 5px;
    }

    .cell {
      font-weight: 600;
      height: 100%;
      background-color: whitesmoke;
      border-top: none;

      &.no-border {
        border-right: none;
      }

      &[data-tip] {
        &:hover {
          &:before {
            font-weight: 700;
            content: "?";
            color: $light-gray;
            font-size: 8px;
            position: absolute;
            top: 2px;
            right: 2px;
          }
        }
      }
    }

    &.dark {
      .cell {
        background-color: #f1f1f1;
      }
    }

    &.shadow {
      box-shadow: 0 4px 6px 0 rgba(235, 235, 235, 0.7);
      z-index: 2;
    }
  }

  .table-row {
    display: flex;
    font-weight: 400;
    font-size: 13px;
    color: $gray;
    align-items: center;
    position: relative;
    width: fit-content;
    min-width: 100%;

    &.selected {
      .cell {
        background-color: $bluish-white;
        transition: background-color 0s;
      }
    }

    &:hover:not(.selected),
    &.gray {
      .cell {
        background-color: $snow-gray;
        transition: background-color 0s;
      }
    }

    &.summary-row {
      .cell {
        font-weight: 700;
        font-size: 15px;

        .realtime-visitors {
          font-weight: 700;
        }
      }
    }

    &.default-rule {
      .cell {
        background-color: $bg-gray;
        opacity: 70%;
      }
    }
  }

  &.medium-text {
    .table-row {
      font-weight: 600;
      font-size: 14px;

      .cell {
        &.medium {
          width: 160px;
        }

        &.aligned-left {
          padding-left: 30px;
        }
      }
    }
  }

  .sticky-bottom-scrollbar {
    width: 100%;
    overflow: auto;
    height: 17px;
    bottom: 0;
    position: fixed;
    z-index: 1000;

    .handle {
      height: 1px;
      overflow: hidden;

      &:before {
        content: "";
      }
    }
  }

  .customize-interface-icon-wrapper {
    padding: 10px 4px 10px 4px;
    opacity: 0.8;
    transition: opacity 0.2s;

    &:hover {
      opacity: 1;
    }
  }
}
