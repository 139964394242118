.slider {
  width: 100%;
  max-width: 360px;
  height: 50px;
  margin-top: 36px;

  .bar {
    position: relative;
    background: #ddd;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    top: 20px;
    height: 10px;
  }

  .bar:nth-child(1) {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background: $red;
  }

  .bar:nth-child(2) {
    background: $orange;
  }

  .bar:nth-child(3) {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background: $green;
  }

  .handle {
    font-size: 0.9em;
    text-align: center;
    background-color: black;
    color: white;
    cursor: pointer;

    &.active {
      background-color: grey;
    }
  }

  .handle {
    width: 12px;
    height: 22px;
    background-color: white;
    border: 1px solid $light-gray;
    border-radius: 2px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    bottom: 14px;
    line-height: 15px;
    position: relative;

    &:before {
      content: "||";
      color: $light-gray;
      font-size: 7px;
    }

    .slider-value {
      font-size: 14px;
      font-weight: 700;
      color: $dark-gray;
      position: absolute;
      top: -24px;
    }
  }
}
