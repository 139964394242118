.wordpress-assets {
  .wordpress-assets-heading {
    text-align: center;
    margin: 20px 0 20px 0;
  }
  .wordpress-assets-container {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    .wordpress-assets-item {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;

      img {
        z-index: 80;
        max-width: 250px;
        max-height: 250px;
      }
    }
  }
}
