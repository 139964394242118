.dark-theme {
  background-color: #121212;
  color: $whisper-gray;

  .preloader-preview-area {
    background-color: #121212;
  }

  .admin-notification {
    background-color: #242424;
  }

  .navigation-bar {
    background-color: #242424;

    .navigation-item {
      color: #9e9e9e;

      &.selected {
        color: #e4e4e4;
      }
    }
  }

  + footer.in-app-footer > .row {
    background-color: #242424;
  }

  .generic-table {
    .header {
      .table-row,
      .table-row:hover {
        .cell {
          background-color: #303030;
          border-right: 1px solid $night-gray;
        }
      }
    }

    .table-row {
      color: $admin-gray;

      .loading {
        background: white;
      }

      .cell {
        border-right: 1px solid $night-gray;
        border-bottom: 1px solid $night-gray;
        background: #1a1a1a;

        &:first-child {
          border-left: 1px solid $night-gray;
        }

        &.medium {
          width: 200px;
        }
      }

      &:hover {
        .cell {
          background-color: $night-gray;
          transition: background-color 0s;
        }
      }

      .sticky-column {
        &.messages-column {
          flex-grow: 0;
        }
      }
    }
  }

  .data-list {
    display: inline-block;

    .list-item {
      color: $admin-gray;

      .loading {
        background: white;
      }

      .cell {
        border-right: 1px solid $night-gray;
        border-bottom: 1px solid $night-gray;
        background: #1a1a1a;

        &:first-child {
          border-left: 1px solid $night-gray;
        }

        &.medium {
          width: 200px;
        }
      }

      &:hover {
        .cell {
          background-color: $night-gray;
          transition: background-color 0s;
        }
      }

      .sticky-column {
        &.messages-column {
          flex-grow: 0;
        }
      }

      &.header {
        .cell {
          background-color: #303030;
          border-right: 1px solid $night-gray;
        }
      }
    }
  }

  .campaigns-pagination {
    .previous,
    .next {
      background-color: #303030;

      svg {
        @include material-ui-icon-stroke(black);
        fill: black;
      }
    }

    .active {
      > a {
        color: white;
      }
    }
  }

  .users-search {
    @extend div.campaigns-search;
  }

  div.react-autosuggest-input {
    > div {
      &:after {
        background-color: #242424;
      }
    }
  }

  div.render-suggestion-container {
    width: inherit;

    div.react-autosuggest-menu-item {
      color: #b0b0b0;
    }
  }

  .suggestions-container-open {
    background-color: #2e2e2e;
    border-width: 0;
  }

  .suggestions-highlighted {
    background-color: #434343;
  }

  .users-filters {
    @extend .filters;

    .add-filter-btn {
      border: 2px solid #303030;
    }

    div.chip-item {
      @extend .general-text;
      color: #b0b0b0;
      background-color: #2e2e2e;

      &:focus {
        background-color: #2e2e2e;
      }
    }
  }
}
