.data-cell-small-text {
  font-size: 10px;
  color: $light-gray;
  height: 10px;
  line-height: 10px;
  margin-bottom: -10px;
}

.campaign-bid {
  position: relative;
  background: inherit;
  .automation-on,
  .roas-on {
    @extend .data-cell-small-text;
  }

  .roas-on {
    margin-top: 3px;
  }

  .bid-underline {
    border-bottom: 2px solid $gainsboro-gray;
    margin-bottom: -2px;
  }
}

.sub-source-bid {
  position: relative;
  background: inherit;

  .bid-underline {
    border-bottom: 2px solid $gainsboro-gray;
    margin-bottom: -2px;
  }
}

.bid-warning {
  font-weight: 600;
  display: flex;
  flex-direction: column;
  font-size: 13px;
  color: $dark-gray;
  padding: 8px 20px;
  width: 172px;
  text-align: center;

  .cancel {
    @extend .general-text;
    @include clickable();
    display: flex;
    justify-content: center;
    align-items: center;
    color: $gray;
    background: white;
    border-radius: 20px;
    border: 2px solid #d1d1d1;
    height: 30px;
    line-height: unset;

    &:hover {
      border: 2px solid rgba(209, 209, 209, 0.8);
    }
  }

  button {
    margin-top: 8px;

    &:last-child {
      margin-bottom: 8px;
    }
  }
}

.with_big_budget_change_message {
  height: 300px;
}

.general-stepper {
  position: relative;
  background: inherit;

  .stepper-underline {
    border-bottom: 2px solid $gainsboro-gray;
    margin-bottom: -2px;
  }
}

.loading-indicator {
  position: absolute;
  top: -2px;
  right: -2px;
  bottom: -2px;
  left: -2px;
  background: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
}

.campaign-actions {
  position: relative;
  background: inherit;
  font-size: 0;
}

.campaign-actions-menu {
  min-width: 140px;
}

.campaign-actions-menu,
.campaign-actions {
  .current-campaign-status {
    font-weight: 700;
    border-bottom: 2px solid $border-gray;
    margin-bottom: 8px;
    padding: 12px 16px 16px 16px;
  }

  .campaign-actions-menu-item {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .boosting-on {
    @extend .data-cell-small-text;
  }

  span {
    margin-left: 10px;
    font-size: 13px;

    &.green {
      color: $green;
    }
  }
}

div.scheduled-description {
  font-size: 10px;
  height: 14px;
  color: #999999;
  padding: 2px 0 0 0;
}

div.campaign-bid-popup-wrapper {
  @include default-box-shadow();
  border-radius: 6px;
}

.big-change-text {
  color: $red;
  font-size: 13px;
  margin-top: 5px;
}

.campaign-bid-popup {
  display: flex;
  flex-direction: column;

  form {
    // For animating between the two modes, we use a css trick to make the browser think that the form element is always
    // before the switch, which might not be like that when we're rendering. The html structure is correct when we view
    // it statically, but we need to do this in order for the animation to look smooth
    order: 1;
  }

  .bid-change-box,
  .auto-bid-box,
  .roas-bid-box {
    overflow: hidden;
    transition: height 0.3s, padding 0.3s, border 0.3s;
    padding: 0 20px;
    width: 172px;

    &.open {
      padding: 16px 20px 0;

      &.loading {
        padding: 16px 20px;
      }
    }
  }

  .bid-change-box {
    height: 0;

    &.open {
      height: 127px;

      &.with-currency {
        width: 200px;
        height: 162px;
      }
      &.big-bid-change {
        height: 160px;
      }
      &.validation-sub-source-bid-change {
        height: 180px;
      }
    }

    &.open.save-bid-disabled {
      height: 97px;
    }

    .bid-change-box-title {
      margin-bottom: 10px;

      .text {
        font-weight: 600;
        font-size: 13px;
        color: $dark-gray;
        flex-grow: 1;
      }
    }
  }

  .auto-bid-box,
  .roas-bid-box {
    height: 0;

    &.open {
      height: 127px;
      border-top: none;

      .auto-bid-box-title,
      .roas-bid-box-title {
        margin-top: 0;
      }
    }

    .auto-bid-box-title,
    .target-roi-box,
    .roas-bid-box-title,
    .roas-roi-box {
      margin-bottom: 10px;

      .text {
        font-weight: 600;
        font-size: 13px;
        color: $dark-gray;
        flex-grow: 1;
      }

      .question-mark {
        font-weight: 700;
        font-size: 9px;
        color: $light-gray;
        vertical-align: top;
        display: inline-block;
        margin-top: -2px;
        margin-left: 2px;
        cursor: default;
      }

      .textbox {
        width: 44px;
        margin: 0;
        flex-basis: 44px;
        flex-shrink: 0;
        flex-grow: 1;
      }
    }

    .switch {
      margin-right: -14px;
    }
  }

  .stepper-button {
    @include clickable();
    width: 30px;
    height: 30px;
    background: $gainsboro-gray;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #979797;
    font-size: 20px;
  }

  .textbox {
    flex-grow: 3;
    margin: 0 8px;
    height: 30px;
    border: 2px solid $gainsboro-gray;
    border-radius: 6px;
    font-size: 13px;
    color: $gray;
    text-align: center;
    width: 100%;

    &:disabled {
      background: $border-gray;
    }
  }

  .save-button-wrapper {
    margin-top: 10px;
    margin-bottom: 10px;
    button {
      min-height: 25px;
      font-size: 13px;
      line-height: 13px;
      width: 100%;
    }

    .bid-validation-text {
      color: $red;
      font-size: 13px;
      margin-top: 5px;
    }
  }

  .auto-bid-switch {
    height: 42px; // We had an animation bug if the height wasn't even (Also: this is the answer to life, the universe, and everything)
    background-color: whitesmoke;
    border-top: 2px solid $border-gray;
    display: flex;
    justify-content: space-between;
    align-items: center;
    order: 2;

    .right-icon {
      margin-right: 20px;
    }

    .switch-text {
      font-weight: 600;
      margin-left: 20px;
      color: $dark-gray;
      font-size: 13px;
    }
  }
}

div.stepper-popup-wrapper {
  @include default-box-shadow();
  border-radius: 6px;
}

.stepper-popup {
  width: 172px;

  .stepper-box {
    height: 0;
    overflow: hidden;
    transition: height 0.3s, padding 0.3s, border 0.3s;
    padding: 0 20px;

    &.open {
      height: 97px;
      padding: 16px 20px;

      &.big-budget-change {
        height: 128px;
      }
    }
  }

  &.with-currency {
    width: 200px;

    .stepper-box {
      &.open {
        height: 130px;

        &.big-budget-change {
          height: 161px;
        }
      }
    }
  }

  .stepper-button {
    @include clickable();
    width: 30px;
    height: 30px;
    background: $gainsboro-gray;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #979797;
    font-size: 20px;
  }

  .textbox {
    flex-grow: 3;
    margin: 0 8px;
    height: 30px;
    border: 2px solid $gainsboro-gray;
    border-radius: 6px;
    font-size: 13px;
    color: $gray;
    text-align: center;
    width: 100%;

    &:disabled {
      background: $border-gray;
    }
  }

  .save-button-wrapper {
    margin-top: 10px;
    button {
      min-height: 25px;
      font-size: 13px;
      line-height: 13px;
      width: 100%;
    }
  }
}

.campaign-avg-cpc-popup {
  min-width: 245px;
  @include default-box-shadow();
  border-radius: 6px;

  .campaign-avg-cpc-popup-loading {
    height: 200px;
  }

  .campaign-average-cpc-menu {
    padding-top: 0;
    padding-bottom: 0;
  }

  .average-cpc-header {
    font-weight: 600;
    height: 40px;
    background-color: $ghost-white;
    text-align: center;
    font-size: 13px;
    color: $dark-gray;
    border-bottom: 1px solid $border-gray;
    padding: 0 20px;
    flex-grow: 1;
  }

  .cpc-list {
    font-weight: 400;
    padding: 8px 20px 12px;
    font-size: 12px;

    .cpc-list-item {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      border-top: 1px solid $border-gray;

      &:first-child {
        border-top: none;
        font-weight: 600;
      }
    }

    .no-data-text {
      text-align: center;
      color: $light-gray;
      font-size: 15px;
    }
  }

  .base-cell {
    display: flex;
    justify-content: center;
    align-items: center;

    &.hour-cell {
      justify-content: flex-start;
      flex: 4;
    }

    &.cpc-cell {
      flex: 3;
    }

    &.roi-cell {
      flex: 2;
    }
  }
}

.campaign-bid-popup,
.stepper-popup,
.bulk-multi-edit-menu {
  .value-in-usd-indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 84px;
    height: 30px;
    margin: 6px auto 0;
    background: #f0f0f0;
    border-radius: 6px;
    font-size: 13px;
    color: $gray;
    white-space: nowrap;
  }

  &.bulk-multi-edit-menu {
    .value-in-usd-indicator {
      border-radius: 2px;
    }
  }
}

.campaign-last-visits-popup {
  min-width: 245px;
  @include default-box-shadow();
  border-radius: 6px;

  .campaign-last-visits-popup-loading {
    height: 200px;
  }

  .campaign-popup-chart-wrapper {
    &.hidden {
      display: none;
    }
  }

  .last-visits-list {
    font-weight: 400;
    padding: 0 20px 4px;
    font-size: 12px;

    .last-visits-list-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 32px;
      border-bottom: 1px solid $border-gray;
      font-weight: 600;

      &.yesterday {
        margin-top: 12px;
      }

      &:last-child {
        border-bottom: none;
      }
    }

    .no-data-text {
      text-align: center;
      color: $light-gray;
      font-size: 15px;
    }
  }
}
