/* Centering the header */
.policy-page {
  width: 100%;
  .policy-header {
    display: flex;
    justify-content: center;
    align-items: center;

    /* Header style */
    .component-header {
      font-size: 25px;
      font-weight: bold;
      margin: 20px;
    }
  }
  .policy-provider {
    display: flex;
    justify-content: center;
    font-family: "Open Sans";
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    align-items: flex-start;
    align-self: stretch;

    svg {
      width: 16px;
      height: 16px;
      margin-right: 7px;
    }
    .article-type-title-content {
      color: #999;
      margin-right: 20px;
    }
    .article-type-title-search {
      color: #c30dde;
      margin-right: 20px;
    }
    .policy-article-link {
      color: #0f86e6;
      margin-right: 20px;
    }
  }
  .policy-body {
    /* Thumbnail grid container */
    background-color: #ffffff;
    border: 3px solid #ffffff;
    border-radius: 5px;
    transition: box-shadow 0s, border-color 0s;
    height: 100%;
    width: 100%;
    padding: 0 20px;
    min-height: 100px;
    max-width: 100%;
    flex-flow: wrap;
    align-content: center;
    justify-content: space-between;
    align-items: stretch;
    margin-bottom: 30px;
    flex-direction: column;
    display: flex;

    .policy-content {
      display: flex;
      padding: 20px;
      justify-content: center;
      align-items: flex-start;
      align-content: flex-start;
      gap: 20px;
      flex-wrap: wrap;
      border-radius: 7px;
      border: 2px solid #edeced;
      width: 100%;
      height: 100%;
    }
    .policy-filters-row {
      display: flex;
      padding-bottom: 10px;
      align-items: center;
      gap: 10px;
      align-self: stretch;
    }

    .policy-loader {
      margin-top: 20px;
    }

    .policy-image-list {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: center;
      margin: 15px;
      margin-bottom: 75px;
      .video-icon {
        opacity: 1;
        position: absolute;
        width: 55px;
        height: 45px;
        margin-left: 10px;
        margin-top: 10px;
        display: flex;
        transition: opacity 0.2s;
        background: rgba(255, 255, 255, 0.9);
        padding: 7px;
        border-radius: 2px;
      }
      .video-clickable-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 70%;
        max-height: 340px;
        cursor: pointer;
      }
      .video-iframe {
        width: 400px;
        height: 400px;
      }
      .policy-text-wrapper {
        padding: 5px;
        font-size: 14px;
        .policy-text-title {
          display: flex;
          font-weight: 600;
          margin: 0px;
        }
        .policy-text-description {
          display: flex;
          margin: 0;
          font-size: 14px;
        }
        .policy-text-headline {
          display: flex;
          margin: 0;
          font-size: 14px;
          color: #666;
        }
        .policy-text-transcribe-video {
          display: block;
          padding: 10px;
          background: #f3f3f3;
          .transcribe_video-title {
            font-weight: 600;
          }
          .transcribe-video-text {
            margin-top: 5px;
            font-size: 14px;
          }
        }
      }
    }

    /* Thumbnail grid item */
    .policy-loader .policy-image-list {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      .MuiGridListTile-tile {
        border: 3px solid #f12d3e; /* Default red border */

        /* Green border for approved images */
        .MuiGridListTile-tile.green {
          border-color: green;
        }
        /* Red border for rejected images */
        .MuiGridListTile-tile.red {
          border-color: #f12d3e;
        }
        &img {
          width: 100%;
          object-fit: cover;
          cursor: pointer;
        }
      }
    }
    .policy-button {
      align-items: center;
      margin-left: auto;
      margin-right: auto;
      padding: 10px 20px;
      display: block;
      .round-button {
        width: 300px;
        min-width: 30px;
        background-color: #77cc64;
        border-radius: 30px;
        padding: 10px 20px;
        font-family: Open Sans, sans-serif;
        font-size: 13px;
        text-decoration: none;
      }
    }
  }
}

// @media screen and (max-width: 767px) {
// }
