.drawer-modal {
  > div:first-child {
    opacity: 0.2 !important;
    background-color: #7b7b7b;
  }
}

.drawer-loading {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  background-color: $ghost-white;
  height: 800px;
  opacity: 0.8;
  border-radius: 12px;
}

.drawer {
  width: 800px;
  max-width: 100vw;

  .drawer-body {
    width: 85%;
    display: flex;
    flex-direction: column;
  }

  .title {
    @include title-font();
    font-size: 30px;
    line-height: 30px;
    padding-top: 55px;
    margin-bottom: 55px;
  }

  .sub-title {
    font-weight: 600;
    margin: 0 0 10px 0;
    align-self: self-end;
    color: $dark-gray;
    font-size: 16px;
    min-height: 24px;
  }

  .content {
    width: 100%;
  }

  .drawer-chip {
    background-color: $blue;
    height: 18px;
    width: 60px;
    color: white;
    font-size: 11px;
    cursor: default;
    position: absolute;
    top: 95px;
    align-self: center;
  }

  .activities-chip {
    @extend .drawer-chip;
    align-self: center;
    width: auto;
  }

  .activity-buttons {
    display: flex;

    .dismiss-all-btn,
    .retry-all-btn {
      margin-left: 20px;
      font-weight: 400;
      color: $light-gray;
      font-size: 13px;

      &:hover {
        color: darken($light-gray, 30%);
      }
    }
  }

  .no-data-wrapper {
    margin-top: 30px;
  }

  .no-data {
    display: flex;
    justify-content: center;
    height: calc(100vh - 161px);
    background-size: contain;
    background-repeat: space;

    .no-data-text {
      margin-top: 10px;
      color: $gray;
      font-size: 15px;
      font-style: italic;
    }
  }

  span.close {
    width: 40px;
    position: absolute;
    top: 0;
    left: 0;

    .close-drawer-icon {
      @include material-ui-icon-stroke($silver-gray);
      fill: $silver-gray;
      margin: 10px 0 0 10px;
    }
  }

  .text {
    font-size: 13px;
    word-break: break-word;
    white-space: pre-line;
  }

  .new-drawer-item {
    background-color: rgba(248, 250, 254, 0.95);
  }

  .note,
  .insight,
  .activity {
    &:first-child {
      border-top: 1px solid $border-gray;
      border-radius: 4px 4px 0 0;
    }

    &:last-child {
      border-bottom: 1px solid $border-gray;
      border-radius: 0 0 4px 4px;

      &:first-child {
        border-radius: 4px;
      }
    }
  }

  .activity {
    @extend .general-drawer-item;
    font-weight: 400;
    font-size: 12px;
    flex-direction: column;
    justify-content: space-around;
    padding: 14px 24px;
    min-height: 72px;

    .content {
      margin-bottom: 4px;
    }
    .friendly-message {
      display: inline-block;
      border: 1px solid #ededed;
      border-radius: 5px;
      background-color: #f3f3f3;
      margin-bottom: 10px;
      font-size: 12px;
      padding: 10px;
    }

    .activity-bottom {
      font-size: 10px;
      color: $light-gray;

      > span {
        &:after {
          margin: 0 8px;
          content: "\2022";
          color: $border-gray;
          font-size: 8px;
        }

        &:last-child {
          &:after {
            content: "";
          }
        }
      }
    }

    .activity-parameter {
      @include clickable();
      font-weight: 600;
      margin: 0 2px;
      color: $blue;

      &.disabled {
        cursor: default;
        color: inherit;
      }
    }
  }

  .options-icon-wrapper {
    @include clickable();
    width: 100%;
  }

  .activity-os-icon {
    > svg {
      position: relative;
      top: 2px;
      height: 12px;
      margin-left: 5px;
    }
  }
  .activity-bottom-divider {
    height: 2px;
    width: 2px;
    color: $border-gray;
    display: inline-block;
    margin: 2px 6px;
  }

  .drawer-divider {
    height: 1px;
    background-color: $border-gray;
    margin: 28px 0;
  }
}
