.filters {
  .add-filter-btn {
    @extend .general-text;
    @include clickable();
    width: 95px;
    height: 30px;
    border: 2px solid #d1d1d1;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    margin-bottom: 18px;

    &:hover {
      border: 2px solid rgba(209, 209, 209, 0.8);
    }
  }

  div.chip-item {
    @extend .general-text;
    background-color: whitesmoke;
    height: 30px;
    border-radius: 15px;
    margin-right: 8px;
    margin-bottom: 18px;
    cursor: default;

    &:focus {
      background-color: whitesmoke;
    }

    svg.delete-icon {
      @include material-ui-icon-stroke(#d1d1d1);

      path:first-child {
        stroke-width: 2px;
      }

      height: 12px;
      transition: stroke 0.2s;

      &:hover {
        @include material-ui-icon-stroke($gray);
      }
    }
  }

  .clear-filters,
  .group-filters {
    opacity: 0;
    transition: opacity 0.2s;
    color: $light-gray;
    text-decoration: underline;
    font-size: 13px;
    height: 30px;
    margin-right: 8px;
    order: 2;
  }

  &:hover {
    .clear-filters,
    .group-filters {
      opacity: 1;
    }
  }
}

div.filter-window {
  min-width: 230px;
  background-color: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.14);
  max-height: calc(100vh - 330px);

  .operators-container {
    display: flex;
    flex-wrap: wrap;
    margin: 12px 8px 6px 8px;

    &:focus {
      .operators-dropdown {
        box-shadow: 0 0 2pt 1pt #5e9ed6;
      }
    }

    .operators-dropdown {
      flex: 1;
      margin: 0 10px;
      max-width: none;
      background-color: $border-gray;
      border-color: transparent;
    }

    .operator {
      width: calc(50% - 16px);
      font-size: 13px;
      padding: 0;
      background-color: transparent;
      color: $dark-gray;
      margin: 0 8px;

      &.selected {
        background-color: $border-gray;
      }
    }
  }

  .back-btn {
    padding-top: 2px;
    height: 20px;
    color: $light-gray;
    border-radius: 1px;
  }

  .filter-group-name {
    color: #909090;
    font-family: Open Sans;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    align-items: center;
    padding: 3px 24px;
    text-transform: uppercase;
    display: flex;
    margin-top: 20px;
  }

  li.filter-results {
    @extend .general-text;
    color: $dark-gray;
    align-items: center;
    padding: 12px 24px;
    height: 6px;

    .plus-icon {
      display: none;
    }

    &.separator {
      margin-top: 15px;
    }
  }

  li.unset-margin-top {
    margin-top: unset !important;
  }

  li:nth-of-type(2) {
    &:not(.DayPicker_weekHeader_li) {
      margin-top: 12px;
    }
  }

  li:last-child {
    &:not(.DayPicker_weekHeader_li) {
      margin-bottom: 6px;
    }
  }

  li.menu-item {
    .filter-result-text {
      display: flex;
      align-items: center;
    }

    .delete {
      color: $light-gray;
      font-size: 13px;
      font-weight: 400;
      visibility: hidden;
    }

    &:hover,
    &:focus {
      background-color: #f6f5f5;
      .plus-icon {
        font-size: 22px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-grow: 1;
        color: $sky-blue;
        border-radius: 1px;
      }
      .delete {
        visibility: visible;
      }
      .filter-result-text {
        flex-grow: 8;
        align-items: center;
        justify-content: flex-start;
      }
    }
  }

  #filter-text-field {
    @extend .general-text;
    color: $dark-gray;
    padding: 0 8px;
    outline: none;
    border: none;
    height: auto;

    &::placeholder {
      color: $light-gray;
    }
  }

  .filter-header {
    width: 100%;
    height: 40px;
    align-items: center;
  }

  .filter-header-title {
    @extend .general-text;
    color: $dark-gray;
    margin-right: 14px;
  }

  li.search-divider {
    padding-top: 6px;
    padding-bottom: 12px;
    border-bottom: 2px solid $border-gray;
    &:hover {
      background-color: transparent;
    }
    &:focus {
      background-color: transparent;
    }
  }

  .unaryFilterWindow {
    height: 34px;
    padding: 0 20px;
    margin-top: 12px;
    cursor: default;
    display: block;
    transition: height 0.25s ease-in;
    background-color: transparent;
    overflow: hidden;

    &:hover {
      background-color: transparent;
    }
    &:focus {
      background-color: transparent;
    }

    &.open {
      height: 78px;
    }
  }

  .binaryFilterWindow {
    height: 84px;
    padding: 0 20px;
    margin-top: 12px;
    cursor: default;
    display: block;
    transition: height 0.25s ease-in;
    background-color: transparent;
    overflow: hidden;

    &:hover {
      background-color: transparent;
    }
    &:focus {
      background-color: transparent;
    }

    &.open {
      height: 128px;
    }

    .to-text {
      padding: 2px 0 2px 14px;
    }
  }

  .text-field-container {
    height: 25px;
    padding: 0 14px;
  }

  .button-container {
    margin-top: 10px;
    display: flex;

    .add-button {
      flex: 1;

      &:focus {
        box-shadow: 0 0 2pt 1pt #5e9ed6;
      }
    }
  }

  .platform-icon {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }

  .DayPicker {
    padding-top: 30px;
    min-width: initial;

    .DayPicker_focusRegion {
      height: initial;
    }
  }

  .datepicker-top-inputs {
    position: absolute;
    width: 100%;
    top: 16px;
    left: 0;
    z-index: 1;
    max-height: 0;
    overflow: hidden;
    transition: max-height 400ms;
    justify-content: space-evenly;

    &.visible {
      max-height: 30px;
    }

    input {
      background-color: $ghost-white;
      border-radius: 13px;
      width: 99px;
      height: 26px;
      text-align: center;
      border: none;
      font-size: 14px;
      color: $gray;
    }
  }

  .date-form-button {
    margin: 8px 18px;
  }

  .save-filters-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(207, 207, 207, 0.7);
  }
}

.generic-filter-dropdown {
  .selected-option {
    color: #cecece;
    background-color: transparent;
  }
}

div.editable-chip-item {
  @extend .general-text;
  display: flex;
  align-items: center;
  background-color: whitesmoke;
  min-height: 30px;
  border-radius: 15px;
  margin-right: 8px;
  margin-bottom: 18px;
  cursor: default;
  order: 1;

  &:focus {
    background-color: whitesmoke;
  }

  &.no-results {
    background-color: #fef0f1;
    border: 2px solid #f1c7cc;
    order: 0;
  }

  &.permanent-filter {
    background-color: #fffcf7;
    border: 2px dotted #f0ad4e;
  }

  &.condition-chip {
    margin-bottom: 10px;
  }

  &.condition-popup-tag {
    margin-bottom: 10px;
  }

  &.condition-chip-item {
    display: inline-flex;
    background-color: #f5f5f5;
    height: 20px;
    margin: 0 5px 0 0;
    cursor: default;
    color: #666;
    font-size: 12px;
    padding: 0;
    min-height: 0;
    margin-bottom: 4px;
  }

  &.condition-chip-item-tooltip {
    display: unset;
    flex-direction: row;
    background: none;
    height: 23px;
    cursor: default;
    color: #666;
    font-size: 12px;
    min-height: 0;
  }

  &.condition-sub-source-chip-item {
    background-color: #fff !important;
    border: 2px solid #efefef;
  }

  svg.delete-icon {
    height: 12px;
    transition: stroke 0.2s;
    color: rgba(0, 0, 0, 0.26);
    cursor: pointer;
    margin: 0 6px 0 -8px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    @include material-ui-icon-stroke(rgba(0, 0, 0, 0.18));

    path:first-child {
      stroke-width: 2px;
    }

    &:hover {
      @include material-ui-icon-stroke($gray);
    }
  }

  .editable-field-container {
    cursor: inherit;
    display: flex;
    align-items: center;
    white-space: pre-wrap;
    padding-left: 16px;
    padding-right: 8px;
    padding-bottom: 1px;
    flex-flow: wrap;

    &.no-delete {
      padding-right: 16px;
    }

    &.condition-container {
      display: flex;
      padding-left: 12px;
      padding-right: 10px;
    }

    &.condition-container-tooltip {
      display: flex;
      right: 50px;
      width: auto;
      padding-left: 12px;
      padding-right: 10px;
      overflow: visible;
    }

    .editable-field {
      margin: 0 3px;
      padding-top: 2px;

      &.active {
        border-bottom: 2px dashed rgba(0, 0, 0, 0.1);
      }

      &.not-active {
        padding-top: 0;
      }

      input {
        background: transparent;
        border: none;
        font-weight: 600;
        color: $gray;
        height: 16px;
        padding: 0;
      }
    }
  }

  .filter-counter {
    color: $light-gray;
    margin-left: 8px;
  }

  .error-balloon {
    &:after {
      background-color: $red;
    }
    &:before {
      //override the balloon tip color with "rgb(242, 41, 57)"
      background: no-repeat
        url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20width%3D%2236px%22%20height%3D%2212px%22%3E%3Cpath%20fill%3D%22rgb(242, 41, 57)%22%20transform%3D%22rotate(0)%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E");
      background-size: 100% auto;
    }
  }
}
