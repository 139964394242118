.creatives-list {
  display: flex;
  flex-direction: column;
  width: 700px;

  &.dynamic-creatives-list {
    width: 730px;
  }
  .creative-container {
    margin-bottom: 20px;
    position: relative;

    &.creative-group-container {
      padding: 0;
    }

    .creative-valid,
    .creative-invalid {
      position: absolute;
      top: -12px;
      right: -14px;
      opacity: 0;
      transition: opacity 0.2s;
      z-index: 2;

      &.visible {
        opacity: 1;
      }
    }

    .creative-valid {
      background-color: $green;
      width: 26px;
      height: 26px;
      border-radius: 50%;
      top: -13px;
      right: -13px;
    }

    .thumbnail-validation {
      margin-right: 20px;
      margin-left: -10px;
      .ratio-title {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: -7px;
        font-size: 11px;
      }
    }
    .multiple-uploaded-active-drag {
      margin: 16px 35px 16px 0;
      width: 600px;
      height: 80px;
      display: flex;
      justify-content: center;
      border: 1px dashed $sky-blue;
      border-radius: 6px;
      text-align: center;
      color: $sky-blue;
      font-size: 11.5px;
      font-weight: 600;
      z-index: 1;
      .drop-container {
        display: flex;
        align-items: center;
        margin: 16px 0;
        .drop-image {
          color: $sky-blue;
          height: 60px;
          width: 60px;
        }
        .drop-icon {
          margin-left: 5px;
        }
      }
    }

    .thumbnails {
      background-color: $white;
      border: 2px solid #e8e8e8;
      border-radius: 5px;
      color: $light-gray;
      transition: box-shadow 0s, border-color 0s;
      padding-left: 30px;
      padding-right: 30px;
      margin-bottom: 28px;
      display: grid;
      grid-template-columns: repeat(5, 1fr); /* Adjust the number of columns as needed */
      grid-gap: 10px;

      &:hover {
        box-shadow: 0 2px 3px rgba(0, 0, 0, 0.04);
        transition: box-shadow 0.1s, border-color 0.1s;
      }
      &.display-ads {
        grid-template-columns: repeat(5, 1fr);

        &:hover {
          box-shadow: 0px;
          transition: box-shadow 0.1s, border-color 0.1s;
        }
      }

      .active-drag {
        position: absolute;
        background: white;
        text-align: center;
        color: $sky-blue;
        font-size: 11.5px;
        font-weight: 600;
        z-index: 1;

        .drop-container {
          display: flex;
          flex-direction: column;
          width: 90px;
          height: 68px;
          border: 1px dashed $sky-blue;
          border-radius: 6px;
          margin: 16px 0;
          align-items: center;
          justify-content: center;

          .drop-image {
            height: 30px;
            width: 34px;
          }
        }
      }
    }

    > span {
      font-weight: 600;
      color: #444444;
      display: inline-block;
      margin-bottom: 6px;
      font-size: 15px;
    }
  }
  .display-ads-title {
    margin-top: 10px;
  }
  .text-container {
    min-height: 100px;
    background-color: $white;
    border: 2px solid #e8e8e8;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    color: $light-gray;
    transition: box-shadow 0s, border-color 0s;
    position: relative;
    padding: 20px 0 25px;

    &.dynamic {
      margin-bottom: 28px;
      padding: 25px;
    }
    &.headlines {
      margin-bottom: 28px;
    }
    &.main {
      margin-bottom: 28px;
      padding: 20px 0 25px 5%;
    }

    &:hover {
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.04);
      transition: box-shadow 0.1s, border-color 0.1s;

      .creative-hover-actions {
        opacity: 1;
      }
    }
    .no-border {
      border: 0px;
      margin-bottom: 0px;
      padding-left: 0px;

      &:hover {
        box-shadow: 0 0px 0px;

        .creative-hover-actions {
          opacity: 1;
        }
      }
    }
    .creative-text {
      margin: 12px 0px 12px;
    }

    .creative-textfield {
      width: 92%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      min-height: 24px;
      border-bottom: 2px solid #d6d6d6;

      > div {
        flex-grow: 1;

        > input {
          font-size: 20px;
          padding: 0;
          color: $dark-gray;

          &::placeholder {
            opacity: initial;
            color: $light-gray;
            font-weight: 400;
          }
        }
      }

      > p {
        font-size: 10px;
        color: #b5b5b5;
        margin: 5px 0 0 0px;
      }

      .text-area {
        font-size: 15px;
        padding: 0;
        color: $dark-gray;

        &::placeholder {
          opacity: initial;
          color: $light-gray;
          font-weight: 400;
        }
      }
    }
  }

  .creative {
    min-height: 100px;
    background-color: $white;
    border: 2px solid #e8e8e8;
    display: flex;
    border-radius: 5px;
    color: $light-gray;
    transition: box-shadow 0s, border-color 0s;
    position: relative;

    &:hover {
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.04);
      transition: box-shadow 0.1s, border-color 0.1s;

      .creative-hover-actions {
        opacity: 1;
      }
    }

    .call-to-action-menu {
      .menu-button {
        font-size: 13px;
        color: $light-gray;
        display: flex;
        align-items: flex-start;

        &.selected {
          color: $dark-gray;
        }

        .arrow {
          @include rounded-arrow($silver);
          width: 16px;
          position: relative;
          bottom: 1px;
        }
      }
    }

    .creative-details {
      display: flex;
      flex-grow: 1;
      flex-wrap: wrap;

      .active-drag {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 42px;
        background: white;
        text-align: center;
        color: $sky-blue;
        font-size: 13px;
        font-weight: 600;
        z-index: 1;

        .drop-container {
          border: 1px dashed $sky-blue;
          border-radius: 6px;
          height: calc(100% - 28px);
          margin: 14px 12px;
          display: flex;
          align-items: center;
          justify-content: center;

          .drop-image {
            height: 37px;
            width: 49px;
            margin-right: 10px;
          }
        }
      }
    }

    .creative-texts {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      flex: 1;
      margin: 16px 16px 16px 0;

      .auto-generate-text {
        color: $gray;
        font-size: 13px;
        min-height: 24px;
      }

      span.switch {
        width: 18px;
        height: 11px;
        margin-right: 5px;
        margin-top: 4px;

        .button-base:before {
          content: "";
        }

        .icon {
          width: 7px;
          height: 7px;
          left: 2px;

          &.extended-icon + input {
            width: 130px;
          }
        }

        .green {
          background-color: #0cb050;

          .icon {
            left: 9px;
          }
        }
      }
    }

    .creative-title,
    .creative-description,
    .creative-generate-text {
      display: flex;
      flex-grow: 0;
      align-items: flex-start;

      .creative-textfield {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        min-height: 24px;

        > div {
          flex-grow: 1;

          &:before,
          &:after {
            border-bottom-color: transparent;
          }
          > input {
            font-size: 13px;
            padding: 0;
            color: $dark-gray;

            &::placeholder {
              opacity: initial;
              color: $light-gray;
              font-weight: 400;
            }
          }
        }

        > p {
          font-size: 10px;
          color: #b5b5b5;
          margin: 5px 0 0 10px;
        }

        .description-text-area {
          font-size: 13px;
          padding: 0;
          color: $dark-gray;

          &::placeholder {
            opacity: initial;
            color: $light-gray;
            font-weight: 400;
          }
        }
      }
    }

    .creative-title {
      .creative-textfield {
        > div > input {
          font-weight: 700;
        }
      }
    }
  }

  .creative-id {
    font-weight: 600;
    display: flex;
    justify-content: center;
    width: 42px;
    align-items: center;
    background-color: #f5f5f5;
    margin-right: 20px;
    color: $gray;
    font-size: 15px;
    flex-direction: column;
    position: relative;
    flex-shrink: 0;
    overflow: hidden;

    .creative-hover-actions {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-color: #f5f5f5;
      opacity: 0;
      transition: opacity 0.2s;
      min-height: 114px;
    }
  }

  .creative-errors,
  .creative-warnings {
    background: $pinkish-pink;
    border-radius: 6px;
    border: 1px solid $border-pink;
    font-size: 12px;
    color: $red;
    padding: 16px 20px;
    margin: 0 20px 20px 0;
    display: flex;
    width: 100%;
    align-items: flex-start;

    &.dynamic-errors {
      margin: 28px 0 0 0;
    }

    &.creative-warnings {
      background: $light-yellow;
      border-color: $border-yellow;
      color: $gray;
    }

    .icon {
      width: 20px;
      flex-grow: 0;
      margin-right: 8px;
    }

    .text {
      flex-grow: 1;
    }

    li {
      margin-top: 4px;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  .thumbnail {
    display: flex;
    flex-shrink: 0;
    width: 100px;
    height: 69px;
    border-radius: 6px;
    align-self: flex-start;
    margin: 16px 35px 16px 0;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    background: white;

    &.static-thumbnail {
      margin-top: 0;
    }

    &.dynamic-thumbnail {
      margin-right: 15px;
    }

    .thumbnail-divider {
      height: 1px;
      width: 75%;
      background: $border-gray;
    }
    .video-icon {
      opacity: 1;
      position: absolute;
      width: 19px;
      height: 14px;
      display: flex;
      transition: opacity 0.2s;
      top: 3px;
      left: 3px;
      background: rgba(255, 255, 255, 0.9);
      padding: 3px;
      border-radius: 3px;
      svg {
        width: 13px;
        height: 8px;
      }
    }

    .existing-thumbnail-actions {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(221, 221, 221, 0.7);
      opacity: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: opacity 0.2s;

      .existing-thumbnail-action-button {
        background: #696766;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        transition: background 0.2s;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          background: #474544;
        }
      }

      .remove-thumbnail {
        margin-right: 2px;
      }

      .crop-thumbnail {
        margin-left: 2px;
      }
    }

    &:hover {
      .existing-thumbnail-actions {
        opacity: 1;
      }

      .no-image .thumbnail-actions {
        opacity: 1;
      }
    }

    &.drop {
      cursor: copy;
    }
    &.multiple-uploaded {
      border: none;
      align-items: center;
      justify-content: center;
      width: 642px;
      height: 80px;
      text-align: left;
      font-size: 12px;
      margin: 0;
      border-radius: 7px;
      background: #f2f2f2;
      .no-image {
        .drop-image {
          width: 50px;
          height: 80px;
        }
        .drop-text {
          margin-left: 5px;
        }
      }
    }

    &.drop,
    &.uploading {
      border: 2px solid #ececec;
    }

    .no-image {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .thumbnail-actions {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 12px;
        color: $gray;
        font-weight: 600;
        justify-content: space-evenly;
        opacity: 0;
        transition: opacity 0.2s;
        background: white;

        .thumbnail-button {
          flex-grow: 1;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        svg {
          fill: $light-gray;
          margin-right: 5px;
        }
      }
    }

    img {
      // the 100x69 container - no cropping styles
      width: 100px;
      height: 69px;
    }

    .thumbnail-background {
      display: flex;
      width: 100%;
      height: 100%;
      padding: 19px 25px 15px 25px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      flex-shrink: 0;
      align-self: stretch;
      border-radius: 7px;
      background: rgba(233, 233, 233, 0.5);

      /* Specific styles for different crop ratios */
      img.portrait {
        /* 4:5 */
        width: 61.5px;
        height: auto;
        flex: 1 0 0;
      }

      img.square {
        /* 1:1 */
        width: 81.76px;
        height: auto;
        flex: 1 0 0;
      }

      img.landscape {
        /* 1.91:1 */
        width: 108px;
        height: 120px;
        flex: 1 0 0;
      }
    }
  }

  .creative-static {
    margin-top: 14px;
    display: flex;
    font-size: 13px;
    border: 1px solid #e8e8e8;
    border-radius: 6px;
    padding: 16px;

    .thumbnail {
      border: none;
      margin-right: 16px;
      margin-bottom: 0;
      width: 60px;
      height: 44px;
      border-radius: 6px;
    }

    .texts {
      flex-grow: 1;
    }

    .call-to-action {
      display: flex;
      align-items: center;
      justify-content: center;
      align-self: flex-end;
      flex-grow: 0;
      border: 1px solid #d9d9d9;
      border-radius: 3px;
      height: 23px;
      padding: 0 12px;
      font-size: 13px;
      color: $dark-gray;
      flex-shrink: 0;
    }

    .creative-title {
      margin: 2px 0;
    }

    .multiline {
      word-break: break-word;
      white-space: pre-line;
      max-width: 95%;
    }
  }
}

.loader-auto-generate {
  font-size: 13px;
  font-family: "Open Sans";
  color: #999;
  text-align: center;
  min-height: 100px;
  background-color: #ffffff;
  border: 2px solid #e8e8e8;
  border-radius: 5px;
  transition: box-shadow 0s, border-color 0s;
  margin-bottom: 15px;
  .loader-circula {
    margin-top: 20px;
  }
}

.add-creative,
.add-targeting-group {
  height: 100px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f4f4f4;
  margin-bottom: 10px;

  &.add-creative-group {
    width: 742px;
  }

  &.small {
    height: 70px;
  }

  > span {
    background: gainsboro;
    height: 34px;
    width: 34px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    .plus-icon {
      height: 12px;
      width: 12px;

      .plus-icon-svg {
        fill: $white;
      }
    }
  }

  &:hover {
    background: #e7e7e7;
  }

  &.selection {
    .ad-type-selection {
      display: none;
      align-items: center;
      justify-content: center;
      color: $gray;
      font-size: 15px;
      font-weight: 600;
      width: 100%;
      height: 100%;
      background: #f4f4f4;
      transition: background 0.1s;

      &:last-child {
        border-radius: 0 5px 5px 0;
      }

      &:hover {
        background: #e7e7e7;
      }
    }

    // First ad-type-selection
    > div:first-of-type {
      border-radius: 5px 0 0 5px;

      // If the first ad-type-selection is also the last one - make all the corners rounded
      &:last-child {
        border-radius: 5px;
      }
    }

    &:hover {
      > span {
        display: none;
      }

      .ad-type-selection {
        display: flex;
      }
    }
  }
}

.creatives-loading {
  height: 300px;
}
