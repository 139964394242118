.admin-page {
  min-height: calc(100vh - 60px);

  .full-page-loader {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1600;
    width: 100%;
    height: 100%;
    overflow: visible;
    background-color: rgba(0, 0, 0, 0.6);
    align-items: center;
    justify-content: center;
    display: flex;

    .loader {
      line-height: 7px;
    }
  }

  .small-title {
    margin-bottom: 10px;
  }

  .space-down {
    margin-bottom: 40px;
  }
}

.admin-navigation-bar {
  .logo {
    width: 114px;
    height: 28px;
  }

  .admin-navigation-tabs {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 1;
  }

  #back-home-btn {
    fill: white;
    position: relative;
    top: 2px;
  }

  .back-home {
    text-decoration: none;
  }
}

.admin-users-page {
  .users-container {
    min-height: 200px;
    text-align: center;
    position: relative;

    .user-avatar {
      margin-right: 20px;
      background-color: transparent;

      svg {
        height: 100%;
      }
    }
  }

  .users-sum-font {
    font-size: 10px;
  }

  .action-button-wrapper {
    @include clickable();
    display: flex;
    align-content: center;
    justify-content: center;
    border: 2px solid $admin-gray;
    border-radius: 50%;
    height: 26px;
    width: 26px;

    .action-button {
      color: #e0e0e0;
      stroke-linejoin: bevel;
      fill: #e0e0e0;
      width: 16px;
    }
  }
}

.admin-sites-page,
.admin-networks-page {
  .sites-container,
  .networks-container {
    .list-item.warning {
      &,
      &:hover {
        .cell {
          background: rgba(234, 178, 183, 0.4);
        }
      }
    }
  }
}

.admin-searchs-page {
  min-width: $site-min-width;
  padding-top: 50px;
  overflow: visible;
  min-height: 100vh;
  height: initial;
  max-height: initial;
  align-self: flex-start;
  box-shadow: none;
  background-color: black;
  .searchs-container {
    max-width: 1240px;
    min-width: $site-min-width;
    .list-item.warning {
      &,
      &:hover {
        .cell {
          background: rgba(234, 178, 183, 0.4);
        }
      }
    }
  }
}

.admin-billings-page {
  .billin-add-button {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 10px;
    align-self: stretch;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .billings-container {
    .list-item.warning {
      &,
      &:hover {
        .cell {
          background: rgba(234, 178, 183, 0.4);
        }
      }
    }
  }
}
.billing-validation-error {
  color: $red;
  font-size: 12px;
  margin-top: 10px;
  .text {
    margin-left: 5px;
  }
}

// This is the base popup class, all the other popups (user, role, module, notifications) will use it
div.admin-page-popup {
  max-width: 1240px;
  min-width: $site-min-width;
  padding: 50px 85px;
  overflow: visible;
  min-height: 100vh;
  height: initial;
  max-height: initial;
  align-self: flex-start;
  box-shadow: none;
  background-color: black;

  .exit-button {
    position: absolute;
    top: 20px;
    right: 20px;

    svg {
      @include material-ui-icon-stroke(#606060);
      fill: #606060;
    }
  }

  .date-field {
    width: 100%;
    padding: 10px;
    .date-lable {
      color: $blue;
      font-size: 12px;
      font-weight: 400;
      padding-left: 10px;
    }
    .SingleDatePicker {
      padding-left: 15px;
      .SingleDatePicker_picker {
        z-index: 1000; /* Adjust this value as needed */
      }
    }
  }
  .percentage-title-color {
    color: $blue;
  }

  .form-text-field {
    color: white;
    font-weight: 400;

    input:focus {
      font-weight: 600;
    }

    &:before {
      border-bottom: 1px solid $light-gray;
    }

    &:after {
      border-bottom: 2px solid white;
    }
  }

  .form-label-field {
    color: $light-gray;
  }

  .align-field {
    padding-left: 0;
    margin-top: 30px;

    .colored-switch:first-child {
      margin-left: -14px;
      vertical-align: top;
    }
  }

  .no-pad-left {
    padding-left: 0;
  }

  .creation-select-wrapper {
    width: 100%;
  }

  .creation-select {
    background: none;
  }

  .colored-switch {
    span.button-base {
      height: auto;
      color: $blue;

      & + .bar {
        background-color: $gainsboro-gray;
        margin-top: -6px;
        opacity: 1;
      }

      &.blue {
        & + .bar {
          background-color: $blue;
        }

        .icon {
        }
      }
    }

    .icon {
      background-color: $white;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
      margin-top: 3px;
    }
  }

  .text-field {
    input {
      width: 460px;
      color: red;
    }

    select {
      width: 460px;
      box-sizing: border-box;
    }

    .text-field-color {
      color: $blue;
    }

    .text-field-line {
      color: #cdcdcd;

      &:before {
        background-color: $light-gray;
        height: 2px;
        border-bottom: 2px solid $light-gray;
      }

      &:after {
        border-bottom: 2px solid $light-gray;
      }

      svg {
        stroke: $zambezi-gray;
        fill: $zambezi-gray;
        stroke-linejoin: bevel;
      }
    }

    &.animated {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.3s;

      &.visible {
        max-height: 80px;
      }
    }
  }

  .space-row {
    margin-top: 20px;
    margin-bottom: 10px;
  }
}

div.billing-popup,
div.role-popup,
div.module-popup,
div.provider-popup,
div.site-popup,
div.network-popup {
  color: $white;

  .popup-title {
    @include title-font();
    font-size: 24px;
    margin-bottom: 12px;
  }

  .popup-subtitle {
    margin-bottom: 20px;
  }

  .save-button {
    margin-top: 30px;
    width: 200px;
  }

  .underline {
    border-bottom: 2px solid $night-gray;
  }

  .reset-campaigns {
    margin-top: 20px;

    span {
      padding: 2px 0;
    }

    .multiline-text {
      white-space: pre-line;
      line-height: 25px;
    }
  }

  .suggested-users-page {
    margin: 30px 0;

    .suggested-users {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-top: 16px;

      div.chip-item {
        @extend .general-text;
        color: #cdcdcd;
        background-color: #4285f5;
        height: 26px;
        border-radius: 15px;
        margin-right: 12px;
        margin-bottom: 4px;
        margin-top: 4px;
        font-weight: 400;

        svg.done-icon {
          @include material-ui-icon-stroke(#d1d1d1);

          height: 12px;
          fill: #d1d1d1;

          path:first-child {
            stroke-width: 0px;
          }

          &:hover {
            @include material-ui-icon-stroke($gray);
            fill: $gray;
          }
        }

        &.stroke {
          background-color: #303030;
        }
      }

      .no-users {
        padding: 10px;
        font-size: 15px;
        font-weight: 500;
      }
    }
  }

  .checkbox {
    color: $light-gray;

    &:not(.checked) {
      &:before {
        background-color: transparent;
      }
    }

    &.checked {
      color: $sky-blue;
    }
  }
}

.validation-dialog {
  display: flex;
  width: 435px;
  height: 200px;
  padding: 0 20px 20px;
  flex-direction: column;
  justify-content: space-evenly;

  .dialog-title {
    font-size: 16px;
    padding: 16px 0;
  }

  .validation-msg {
    display: flex;
    font-size: 14px;
    color: $dark-gray;
    flex-direction: column;
    white-space: pre-line;
  }

  .validation-text {
    > div {
      width: 50%;
    }

    .text-field {
      &:before {
        border-bottom: 2px solid #d6d6d6;
      }
    }

    .check-mark {
      top: 20px;
      right: 25px;
      fill: $green;
      position: relative;
    }
  }

  .validation-buttons {
    display: flex;
    justify-content: flex-end;

    > span {
      @include clickable();
      margin-left: 18px;
      display: flex;
      font-size: 14px;
      align-items: center;
    }
  }
}

div.role-popup {
  .modules-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .module {
    align-items: center;
    width: 33%;
    margin: 0;

    .module-checkbox-wrapper {
      text-align: right;
    }

    .module-name {
      text-align: left;
    }
  }

  .module-checkbox {
    color: #bbbbbb;
  }
}

div.module-popup {
  .chip-item {
    margin-right: 6px;
    cursor: default;
  }
}

ul.sections-switcher {
  list-style: none;
  display: flex;
  padding: 0;
  margin-top: 30px;
  padding-bottom: 15px;
  justify-content: flex-start;

  li {
    font-size: 14px;
    color: $light-gray;
    margin-right: 20px;

    &.selected {
      color: $white;
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }

    &.aligned-right {
      margin-left: auto; // https://stackoverflow.com/questions/35269947/css-align-one-item-right-with-flexbox
      margin-right: 20px;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

div.user-popup {
  .username-title {
    @include title-font();
    font-size: 20px;
    color: $white;
  }

  .underline {
    border-bottom: 2px solid $night-gray;
  }

  .field-title {
    margin-top: 20px;
    color: $light-gray;
    font-size: 12px;
    font-weight: 400;
  }

  .user-avatar {
    margin-right: 15px;
    width: 58px;
    height: 58px;
    background-color: transparent;

    svg {
      height: 100%;
    }
  }

  .userRoles {
    .generic-dropdown-selection {
      background: $night-gray;
      border: 2px solid $light-gray;
      color: #cdcdcd;

      &.user-roles-dropdown {
        margin: 16px 0;
        min-width: 200px;
      }
    }
    .subSection {
      background-color: #181818;
      padding: 10px 40px;

      .subSectionTitle {
        color: $whisper-gray;
        font-size: 18px;
        font-weight: 600;
      }
    }

    .assignRole {
      margin-top: 60px;

      .networks {
        margin: 0 -15px;

        .network-roles {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          margin-top: 16px;

          .network-title {
            font-weight: 600;
            color: $white;
            font-size: 13px;
            margin-right: 8px;
          }
        }
      }
    }

    .existingRole {
      margin-top: 10px;

      .rolesList {
        margin: 20px 0;
        border-top: $night-gray solid 1px;

        .roleRow {
          flex-wrap: wrap;
          width: 100%;
          border-bottom: $night-gray solid 1px;
          padding: 5px 0;

          .closeContainer {
            flex: 1;
            justify-content: flex-end;
            align-items: center;

            svg.delete-icon {
              @include material-ui-icon-stroke(#d1d1d1);
              height: 15px;
              fill: #d1d1d1;

              path:first-child {
                stroke-width: 2px;
              }

              &:hover {
                @include material-ui-icon-stroke($gray);
                fill: $gray;
              }
            }
          }
        }
      }
    }

    div.chip-item {
      @extend .general-text;
      color: #cdcdcd;
      background-color: #303030;
      height: 26px;
      border-radius: 15px;
      margin-right: 12px;
      margin-bottom: 4px;
      margin-top: 4px;
      cursor: default;
      font-weight: 400;

      &.stroke {
        text-decoration: line-through;
      }

      svg.delete-icon {
        @include material-ui-icon-stroke(#d1d1d1);

        height: 12px;
        transition: stroke 0.2s;

        path:first-child {
          stroke-width: 2px;
        }

        &:hover {
          @include material-ui-icon-stroke($gray);
        }
      }
    }

    .text-field {
      margin-right: 16px;
    }
  }

  .whiteText {
    @extend .general-text;
    color: white;
    line-height: 31px;
    margin-right: 8px;
  }

  .userSites {
    .subSection {
      background-color: #181818;
      padding: 10px 40px;

      .subSectionTitle {
        color: $whisper-gray;
        font-size: 18px;
        font-weight: 600;
      }
    }

    .assignSites {
      margin-top: 60px;
    }

    .existingSites {
      margin-top: 10px;

      .sitesList {
        margin: 20px 0;
        border-top: $night-gray solid 1px;

        .siteRow {
          width: 100%;
          border-bottom: $night-gray solid 1px;
          padding: 5px 0;

          .closeContainer {
            flex: 1;
            justify-content: flex-end;
            align-items: center;
          }
        }
      }
    }

    div.chip-item {
      @extend .general-text;
      color: #cdcdcd;
      background-color: #303030;
      height: 26px;
      border-radius: 15px;
      margin-right: 12px;
      margin-bottom: 4px;
      margin-top: 4px;
      cursor: default;
      font-weight: 400;

      svg.delete-icon {
        @include material-ui-icon-stroke(#d1d1d1);

        height: 12px;
        fill: #d1d1d1;
        transition: stroke 0.2s;

        path:first-child {
          stroke-width: 2px;
        }

        &:hover {
          @include material-ui-icon-stroke($gray);
          fill: $gray;
        }
      }
    }
  }

  .investigate-user {
    margin-top: 60px;

    .sub-section {
      background-color: #181818;
      padding: 10px 40px;

      .sub-section-title {
        color: $whisper-gray;
        font-size: 18px;
        font-weight: 600;
      }
    }

    .queries {
      margin-top: 10px;

      .copy-icon {
        color: white;
        margin-left: 10px;
      }
    }

    .text-field {
      color: white;
      padding: 10px 0;
    }
  }
}

.admin-messages-page {
  .sub-title {
    font-size: 30px;
    margin-bottom: 8px;
  }

  .messages-container {
    min-height: 200px;
    text-align: center;
    position: relative;
  }

  .users-sum-font {
    font-size: 10px;
  }
}

div.admin-notification-popup-backdrop {
  background-color: #404040;
}

div.admin-notification-popup {
  padding: 50px 85px;
  background-color: black;

  .admin-notification-popup-container {
    flex-direction: column;
    flex: 1 0 100%;
    display: flex;

    .top-section {
      height: 200px;
      background-color: transparent;

      .main-title {
        color: $white;
        font-size: 40px;
        font-weight: 600;
      }
    }

    .bottom-section {
      flex: auto;
      background-color: black;
      border-radius: 25px 25px 0 0;
      margin: 0 80px;
      padding: 60px 80px;

      .sub-title {
        color: #cdcdcd;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 25px;
      }

      .form-text-field {
        color: white;
        font-weight: 200;

        &:before {
          border-bottom: 2px solid $light-gray;
        }

        &:after {
          border-bottom: 2px solid $light-gray;
        }
      }

      .form-label-field {
        color: $light-gray;
      }

      .align-field {
        padding-left: 0;
      }

      .text-field-line {
        color: #cdcdcd;

        &:before {
          background-color: $light-gray;
          height: 2px;
        }

        &:after {
          background-color: $white;
        }

        svg {
          stroke: $zambezi-gray;
          fill: $zambezi-gray;
          stroke-linejoin: bevel;
        }
      }

      .align-submit-button {
        padding-left: 0;
        margin-top: 20px;
      }

      .date-error {
        color: $red;
      }
    }
  }

  .align-field {
    margin-top: 0;
  }
}

div.admin-providers-page {
  .sub-title {
    font-size: 30px;
    margin-bottom: 8px;
  }

  .list-item.header:first-child > .cell:last-child {
    border-top-right-radius: 12px;
    border-right: none;
  }
}

div.provider-popup {
  .traffic-provider {
    color: white;
  }

  .provider-checkbox {
    color: #bbbbbb;
  }

  .exit-button {
    position: absolute;
    top: 20px;
    right: 20px;

    svg {
      @include material-ui-icon-stroke(#606060);
      fill: #606060;
    }
  }
}

.config-page {
  .admin-select {
    color: $silver;
  }

  .admin-dropdown {
    position: relative;
    width: fit-content;
  }

  .arrow {
    bottom: 14px;
    right: 2px;
    position: absolute;
    pointer-events: none;
    height: 20px;
    width: 20px;
    @include rounded-arrow($silver);
  }

  .divider {
    background-color: $light-gray;
    margin: 20px 0 -6px 0;
  }

  .save-wrapper {
    height: 70px;
    display: flex;
    align-items: center;

    .error-message {
      font-size: 15px;
      margin-left: 5px;
    }

    > svg {
      margin-left: 24px;
    }
  }

  .save-button,
  .reload-button {
    width: 170px;
  }

  .text-field {
    background-color: $border-gray;
    border-radius: 6px;
    width: 100%;
    padding: 12px 10px 10px 28px;
    min-height: 55vh;
  }

  .text-field-line {
    &:after {
      border-bottom: none;
    }

    &:before {
      border-bottom: none;
    }
  }
}

.site-revenue-share {
  display: flex;
  flex-direction: column;

  &.dark-theme {
    background-color: transparent;
  }

  .current-share {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 30px 0;
  }

  .share-history {
    min-height: 700px;

    .action-button-wrapper {
      @include clickable();
      display: flex;
      align-content: center;
      justify-content: center;
      border: 2px solid $admin-gray;
      border-radius: 50%;
      height: 26px;
      width: 26px;

      .action-button {
        color: $admin-gray;
        fill: $admin-gray;
        width: 16px;
      }
    }
  }
}

.rev-share-dialog {
  width: 300px;
  height: fit-content;
  padding: 12px;

  .form-buttons {
    display: flex;
    justify-content: space-around;
  }

  .rev-share-total {
    margin-bottom: 10px;
    font-size: 13px;
    display: flex;
    align-items: center;
    white-space: pre;

    > span {
      color: $red;
    }

    .check-mark {
      display: none;
      font-size: 14px;
      color: $green;
    }

    &.success {
      > span {
        color: $light-gray;
      }

      .check-mark {
        display: inline-block;
      }
    }
  }

  .validation-error {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .text {
      margin-left: 8px;
      font-size: 13px;
      color: $red;
    }
  }
}

.roles-and-modules {
  .underline {
    border-bottom: 2px solid $night-gray;
    margin-bottom: 16px;
  }

  .sections-switcher {
    li {
      font-size: 20px;
    }
  }
}
