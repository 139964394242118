.advanced-search-drawer {
  .advanced-search-drawer-body {
    margin: 60px 70px;

    .drawer-title {
      @include title-font();
      color: $dark-gray;
      font-size: 30px;
      font-weight: 500;
      align-self: center;
      margin-bottom: 60px;
    }

    .advanced-search-filter {
      flex-direction: column;

      .filter-category {
        .category-title {
          color: $dark-gray;
          font-size: 14px;
          font-weight: 400;
          margin-bottom: 10px;
        }

        .category-chips {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 12px;
        }
      }
    }

    .button-container {
      display: flex;
      margin-top: 20px;

      .search-button {
        flex: 1;
        color: white;
        height: 50px;
        border-radius: 25px;
      }
    }

    .clear-button {
      color: $light-gray;
      text-decoration: underline;
      font-size: 13px;
      height: 30px;
      margin-top: 16px;
    }

    .collection-search {
      display: flex;
      margin-bottom: 40px;

      .collection-autosuggest-container {
        flex: 1;
        width: initial;
      }

      .react-autosuggest-render-input {
        flex: 1;
        width: initial;

        #search-span {
          right: 15px;
        }

        .collection-react-autosuggest-input {
          flex: 1;
          width: initial;
          border-bottom: 2px solid #d6d6d6;

          > div {
            padding-left: initial;

            > input {
              font-size: 16px;

              &::placeholder {
                display: none;
                padding: 0px;
              }
            }
          }
        }
      }
    }

    .filter-loader {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 18px;
      width: 95px;
      height: 30px;
    }
  }
}

.advanced-search-page {
  .bulk-edit-row {
    margin-bottom: 20px;
    margin-top: 40px;

    button.bulk-edit-button {
      margin-right: 10px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 13px;
      border: 2px solid transparent;
      transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

      > span {
        height: 0;
      }

      &:disabled {
        background: $white;
        border: 2px solid #d1d1d1;
        color: $gray;
      }
    }
  }

  .edit-filters-button {
    height: 30px;
    margin-right: 10px;
  }

  .back-button-container {
    color: $light-gray;
    font-size: 15px;
    font-weight: 400;
    width: fit-content;

    .back-button {
      height: 16px;
      fill: #bdbdbd;
      margin-right: -2px;
      margin-top: 1px;
    }
  }
}
