.generic-autosuggest {
  display: flex;
  flex-direction: column;
  align-items: center;

  .autosuggest-options {
    width: 100%;

    input[type="checkbox"] {
      margin-right: 6px;
    }

    .group-title {
      font-weight: 600;
    }

    .group-item {
      padding-left: 32px;
    }
  }

  .react-autosuggest-input-menu-item {
    position: sticky;
    top: 0;
    z-index: 1;
    background: $white;
    padding: 8px 16px;
    width: 100%;
  }

  div.text-field-wrapper {
    div.text-field {
      border: 2px solid $border-gray;
      border-radius: 20px;
      height: 28px;
      width: 100%;

      input.react-autosuggest__input {
        height: 30px;
        width: 100%;
        @extend .general-text;
        color: $dark-gray;
        padding: 0 8px;
        outline: none;
        border: none;

        &::placeholder {
          opacity: 0.5;
          color: $dark-gray;
        }
      }

      > div {
        padding-right: 20px;
        height: 100%;

        input[type="text"] {
          height: 100%;
        }
      }
    }
  }

  li {
    &.react-autosuggest-input-menu-item {
      height: 40px;
      padding-left: 14px;

      &:hover {
        background-color: $white;
      }
    }

    &.option-title {
      &,
      &:focus {
        background-color: $white;
      }
    }
  }

  svg.search-icon,
  svg.clear-filter-text {
    position: absolute;
    right: 26px;
    top: 15px;
  }
}
