div.jsoneditor-react-container {
  div.jsoneditor {
    height: 500px;
    border: 1px solid $night-gray;
  }

  div.jsoneditor-menu {
    background-color: $dark-gray;
    border-bottom: 1px solid $night-gray;
  }

  div.jsoneditor-tree {
    background-color: white;
  }
}
