.number-text-field {
  flex-grow: 3;
  border: 0;
  border-bottom: 2px solid #d6d6d6;
  padding-top: 34px;
  margin-bottom: 3px;
  font-size: 16px;
  width: 100%;
  color: $dark-gray;

  &.limit {
    padding-top: 0;
  }
}

.condition-creation-field {
  align-items: flex-start;
}

.automation-group {
  z-index: 2;
  display: flex;
  overflow: hidden;
  border-radius: 12px;
  margin-bottom: 14px;
  margin-top: 14px;
  flex-direction: column;
  justify-content: flex-start;
  border: 2px solid #e8e8e8;
}

.automation-group-title {
  font-size: 16px;
  color: #444;
  display: flex;
  justify-content: space-between;
  background: #f9f9f9;
  padding: 15px 20px;
  font-weight: 600;
}

.automation-group-fields {
  padding: 15px 35px 30px;
}

.automation-action-value-fields {
  display: flex;
  justify-content: space-between;
  margin-bottom: 7px;

  .rule-field {
    width: 50%;
    color: $dark-gray;
    max-width: 150px;
  }

  .value-field {
    width: 45%;
    color: $dark-gray;
  }
}

div.value-type-dropdown-menu {
  max-width: 300px;
  width: 100%;

  li.react-autosuggest-input-menu-item {
    > div,
    .text-field-wrapper,
    .text-field {
      width: calc(100% + 16px);
    }
  }
}

div.value-type-button {
  margin: 0;
  max-width: 150px;
}

.automation-creation-errors {
  display: flex;
  flex-direction: column;
  margin-top: 12px;

  .automation-error-text {
    color: red;
    font-size: 13px;
    font-weight: 400;
    max-width: 400px;
  }
}

.automation-validation-error {
  color: red;
  font-size: 13px;
  font-weight: 600;
  max-width: 400px;
}

.automation-creation-network-label {
  color: $blue;
  font-size: 12px;
  margin-top: 25px;
}
