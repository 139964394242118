div.campaign-popup-container {
  overflow: auto;

  @media screen and (max-width: $site-min-width) {
    & {
      justify-content: flex-start;
    }
  }
}

div.campaign-popup-backdrop {
  background-color: rgba(207, 207, 207, 0.7);
}

div.campaign-popup {
  max-width: 1700px;
  min-width: $site-min-width;
  padding: 50px 70px;
  overflow: visible;
  min-height: 100vh;
  height: initial;
  max-height: initial;
  align-self: flex-start;
  box-shadow: none;

  .title {
    @include title-font();
    font-size: 30px;
    line-height: 30px;
    color: $dark-gray;
  }

  .campaign-popup-exit-button {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .campaign-title {
    @include title-font();
    font-size: 30px;
    line-height: 30px;
    word-break: break-word;
  }

  .campaign-datepicker {
    flex-shrink: 0;
    padding-left: 20px;
  }

  .campaign-popup-loading {
    height: 400px;
  }

  ul.campaign-details-list {
    padding: 0;
    margin: 12px 0 0 0;
    background: $white;

    li {
      display: inline-flex;
      font-size: 13px;
      color: $gray;
      margin-right: 12px;
      vertical-align: top;
      background: inherit;

      &:after {
        margin-left: 12px;
        content: "\00b7";
        color: $light-gray;
      }

      &:last-child {
        &:after {
          content: "";
        }
      }
    }

    .target-roi-title {
      display: inline-block;
      margin-left: 4px;
    }
  }

  .campaign-tags {
    display: flex;
    margin-top: 11px;
    flex-wrap: wrap;

    div {
      margin-right: 4px;

      &.operator-not-active {
        margin: 2px;
      }
    }

    .add-tag {
      margin: 0;
      width: 26px;
      height: 26px;
      display: flex;
      align-items: center;
      border-radius: 13px;
      justify-content: center;
      border: 2px solid #ededed;

      .plus-icon {
        stroke: #cfcfcf;
        width: 10px;
        height: 10px;

        .plus-icon-svg {
          fill: #cfcfcf;
        }
      }
    }

    .generic-dropdown-selection {
      &.hidden {
        display: none;
      }
    }

    .chip-item {
      @include chip-item(whitesmoke, #d1d1d1);
      height: 26px;
      cursor: default;
    }
  }

  .separator {
    background-color: #d5d5d5;
    height: 15px;
    width: 1px;
    margin-right: 10px;
    margin-left: -10px;
    margin-top: 2px;
  }

  .campaign-actions {
    .current-campaign-status {
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0;
      border: 0;
      margin-top: -2px;
    }
  }

  .campaign-options-menu-button {
    background: whitesmoke;
    border-radius: 50%;
    margin-left: 20px;

    &,
    .options-icon-wrapper {
      width: 37px;
      height: 37px;
    }
  }

  .campaign-bid {
    margin-left: 12px;
  }

  .budget-title {
    margin-right: 12px;
  }

  .campaign-popup-main-top {
    padding-bottom: 30px;
    margin-bottom: 20px;
    border-bottom: 2px solid $border-gray;

    .campaign-bid,
    .general-stepper {
      background: $white;
    }
  }

  .article-link {
    color: $light-gray;
    font-size: 13px;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;

    .article-link-icon {
      width: 11px;
      height: 11px;
      margin-left: 7px;
    }
  }

  .view-campaigns {
    color: $light-gray;
    font-size: 13px;
    font-weight: 400;
    margin-left: 7px;
  }
  .main-keyword-sections-switcher {
    display: flex;
    padding-top: 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;

    ul.keyword-sections-switcher {
      list-style: none;
      display: flex;
      padding: 4px;
      align-items: center;
      gap: 10px;
      border-radius: 20px;
      border: 2px solid rgba(232, 232, 232, 0.5);
      font-size: 13px;
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      line-height: normal;

      li {
        display: flex;
        height: 26px;
        padding: 0px 20px;
        justify-content: center;
        align-items: center;
        gap: 20px;
        border-radius: 20px;

        &.selected {
          background: #efefef;
          color: #444;
        }
      }
    }
  }

  ul.campaigns-popup-sections-switcher {
    list-style: none;
    display: flex;
    padding: 0;
    margin-bottom: 40px;
    justify-content: space-between;
    font-size: 14px;

    li {
      font-size: 14px;
      color: $light-gray;
      margin-right: 20px;

      &.selected {
        font-weight: 700;
        color: $blue;
      }

      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }

      &.aligned-right {
        margin-left: auto; // https://stackoverflow.com/questions/35269947/css-align-one-item-right-with-flexbox
        margin-right: 20px;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .campaign-popup-chart {
    input[type="checkbox"] {
      + span.custom-checkbox-style {
        position: absolute;
        width: 14px;
        height: 14px;
        display: flex;
        border-radius: 2px;
        pointer-events: none;
        font-weight: 300;
        font-size: 8px;
        color: white;
        align-items: flex-start;
        justify-content: center;
        padding-top: 1px;
      }

      &:not(:checked) {
        + span.custom-checkbox-style {
          background-color: white !important;
          border: 2px solid $gainsboro-gray !important;
        }
      }
    }
  }

  .performance-by-hour-chart,
  .performance-by-page-chart {
    .visit-value-tooltip {
      display: flex;
      flex-direction: column;

      .line {
        height: 16px;
        position: relative;
        left: 3px;
        border-left: 2px solid #268f4f;
        margin-top: -6px;
        margin-bottom: -8px;
      }

      .tooltip-row {
        .bullet {
          width: 6px;
          height: 6px;
          border: 2px solid green;
          border-radius: 3px;
          margin-right: 4px;
          margin-left: 1px;
        }
      }

      .limiter {
        height: 0;
        border-bottom: 1px solid $border-gray;
        margin: 5px 0;
      }
    }
  }
  .sub-source-search {
    display: flex;
    align-items: flex-end;
  }

  .campaign-popup-list {
    margin-top: 40px;
    position: relative;
  }

  .campaign-popup-chart-wrapper {
    position: relative;

    .empty-chart-indicator {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: calc(100% - 170px);
      transform: translateY(-50%);
      position: absolute;
      color: $light-gray;
      font-size: 13px;
      z-index: 2;
      top: calc(50% - 8px);

      .chart-icon {
        width: 48px;
        height: 48px;
        display: flex;
        border-radius: 50%;
        margin-bottom: 10px;
        align-items: center;
        justify-content: center;
        background-color: $border-gray;

        .show-chart-icon {
          fill: $light-gray;
        }
      }
    }
  }

  .pages-ordering {
    .cancel-button-color {
      color: $light-gray;
    }

    .mask {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: $white;
      z-index: 2;
      opacity: 0.4;
    }

    position: relative;
    font-size: 13px;
    overflow: hidden;

    .buttons {
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
      margin-bottom: 20px;

      .button {
        display: flex;
        justify-content: center;
        align-self: center;
        margin-left: 10px;
        min-width: 72px;
      }
    }

    .list {
      display: flex;
      width: 100%;

      > div {
        width: 100%;
      }

      :active {
        border-top: 1px solid whitesmoke;
      }

      &.header {
        > div {
          width: initial;
          min-height: 45px;
        }

        > div:first-child {
          border-top-left-radius: 12px;
        }

        > div:last-child {
          border-top-right-radius: 12px;
        }
      }

      &:not(.header) {
        flex-direction: column;
      }

      .item {
        display: flex;
        width: 100%;
        align-items: center;

        img {
          width: 50px;
          height: 50px;
          margin-right: 20px;
        }

        .page-index {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 10px;
          border-style: solid;
          border-width: 1px;
          border-color: $light-gray;
          width: 30px;
          height: 30px;
          border-radius: 20%;
        }
      }

      .small-box {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 100px;
        max-width: 100px;
      }

      .large-box {
        min-width: 500px;
        flex-grow: 2;
      }
    }
  }
}

.legend-item-text-keyword {
  display: flex;
  align-items: center;
  padding-left: 25px;
  padding-bottom: 2px;
  color: $dark-gray;
}

.highcharts-legend-item-hidden,
.highcharts-legend-item {
  .legend-item-wrapper {
    display: flex;
    align-items: center;
    width: 110px;
    height: 25px;

    .legend-item-symbol {
      width: 14px;
      height: 14px;
      color: $white;
      border-radius: 50%;
      border: 3px solid $gainsboro-gray;
    }

    .legend-item-text {
      width: 96px;
      padding-left: 4px;
      color: $dark-gray;
    }
  }

  .highcharts-point {
    display: none;
  }
}

.highcharts-legend-item-hidden {
  .legend-item-symbol {
    border: 3px solid $gainsboro-gray !important;
  }
}

.highcharts-tooltip-container {
  z-index: 1400 !important;
}
