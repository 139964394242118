.animation-ball {
  background-color: $blue;
}

.page-preloader {
  top: 0;
  left: 0;
  z-index: 999;
  position: fixed;
  height: 100vh;
  width: 100%;
  text-align: center;

  .preloader-preview-area {
    -webkit-animation-delay: -0.2s;
    animation-delay: -0.2s;
    top: calc(50% - 30px);
    -webkit-transform: translateY(100%);
    -ms-transform: translateY(100%);
    transform: translateY(100%);
    max-height: calc(50% - 20px);
    opacity: 1;
    width: 100%;
    text-align: center;
    position: absolute;
  }
}

.ball-pulse-sync {
  display: inline-block;
}

.ball-pulse-sync > div {
  width: 15px;
  height: 15px;
  border-radius: 100%;
  margin: 2px;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  display: inline-block;
}

.ball-pulse-sync > div:nth-child(1) {
  -webkit-animation: ball-pulse-sync 0.6s -0.21s infinite ease-in-out;
  animation: ball-pulse-sync 0.6s -0.21s infinite ease-in-out;
}

.ball-pulse-sync > div:nth-child(2) {
  -webkit-animation: ball-pulse-sync 0.6s -0.14s infinite ease-in-out;
  animation: ball-pulse-sync 0.6s -0.14s infinite ease-in-out;
}

.ball-pulse-sync > div:nth-child(3) {
  -webkit-animation: ball-pulse-sync 0.6s -70ms infinite ease-in-out;
  animation: ball-pulse-sync 0.6s -70ms infinite ease-in-out;
}

@-webkit-keyframes ball-pulse-sync {
  33% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
  66% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes ball-pulse-sync {
  33% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
  66% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
