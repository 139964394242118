.campaigns-pagination {
  align-items: center;
  height: 70px;

  .active {
    > a {
      color: $blue;
      &:focus {
        text-decoration: none;
      }
    }
  }

  .break-me {
    font-weight: 700;
    width: 24px;
    height: 100%;
    font-size: 15px;
    color: $light-gray;
    pointer-events: none;
  }

  .previous,
  .next {
    padding: 0;
    background-color: $blue;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    margin-left: 8px;
    > a {
      color: white;
      font-size: 0;
    }
    svg {
      @include material-ui-icon-stroke($white);
    }
  }

  li {
    @include clickable();
    padding: 0 6px;
    display: flex;
    align-items: center;

    > a {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      font-size: 15px;
      color: $light-gray;
      width: 100%;
      height: 100%;
      text-align: center;
    }
  }
}
