div.campaign-creation-wizard-container,
.experimental-bulk-creation,
.targeting-group-preset-popup {
  overflow: auto;

  @media screen and (max-width: $site-min-width) {
    & {
      justify-content: flex-start;
    }
  }

  .checkbox {
    color: $gainsboro-gray;
    height: fit-content;
    width: fit-content;
    margin-right: 10px;
    padding: 0;

    &:not(.checked) {
      &:before {
        content: " ";
        top: 4px;
        left: 4px;
        right: 4px;
        bottom: 4px;
        background: white;
        position: absolute;
      }
    }

    &.checked {
      color: $sky-blue;
    }

    svg {
      width: 18px;
      height: 18px;
    }
  }
}

div.campaign-creation-wizard-backdrop {
  background-color: rgba(207, 207, 207, 0.7);
}

div.campaign-creation-wizard-popup {
  min-width: $site-min-width;
  overflow: visible;
  min-height: 100vh;
  height: initial;
  max-height: initial;
  align-self: flex-start;
  background: $bg-gray;

  .campaign-creation-wizard-exit-button {
    position: absolute;
    top: 20px;
    right: 20px;
  }
}

div.breadcrumbs {
  display: flex;
  justify-content: center;

  .step {
    &:first-child {
      margin-left: 30px;
    }

    .step-number {
      border-radius: 50%;
      width: 22px;
      height: 22px;
      font-size: 14px;
      background-color: #c5c5c5;
      color: $white;
      margin-right: 6px;
      display: inline-block;
      text-align: center;
    }

    .step-name {
      font-weight: 600;
      font-size: 14px;
      margin-right: 30px;
      color: #a8a8a8;
    }

    &.selected {
      .step-number {
        background-color: $blue;
        color: $white;
      }

      .step-name {
        color: $blue;
      }
    }
  }
}

div.step-title {
  @include title-font();
  font-size: 25px;
  line-height: 25px;
  color: $dark-gray;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

div.duplicate-warning {
  position: relative;

  .duplicate-warning-mask {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
}

div.step-sub-title {
  font-size: 22px;
  line-height: 22px;
  color: $dark-gray;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 30px;
  font-weight: 200;
}

.campaign-creation-sources,
.campaign-creation-targeting {
  .campaign-creation-source,
  .campaign-targeting-group {
    z-index: 2;
    width: 700px;
    display: flex;
    font-size: 13px;
    font-weight: 400;
    overflow: hidden;
    max-height: 56px;
    background: $white;
    border-radius: 12px;
    position: relative;
    margin-bottom: 14px;
    flex-direction: column;
    justify-content: flex-start;
    border: 2px solid #e8e8e8;
    transition: max-height 0.25s ease-out;

    &.selected {
      max-height: 770px;
      justify-content: flex-start;
      transition: max-height 0.25s ease-in;
    }
  }
}

.campaign-creation-targeting {
  width: 100%;
  padding: 0 100px;

  &.creatives-groups {
    padding: 0 70px;
  }

  .conversion-events-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;
  }

  .campaign-targeting-group {
    width: 680px;
    max-height: initial;
    background: $snow-gray;

    &.creatives-group {
      width: 742px;
    }

    .creatives-group-fields {
      padding: 35px 35px 25px 35px;
    }

    .group-fields {
      padding: 15px 35px 35px;
    }

    &.open {
      background: $white;

      .group-fields {
        padding: 15px 35px 35px;
      }
    }

    .group-summary {
      color: $gray;
      font-size: 15px;

      .summary-field {
        margin-top: 4px;

        &:first-child {
          margin-top: 0;
        }
      }

      .summary-field-title {
        font-weight: 600;
        color: $dark-gray;
      }

      .not-selected {
        font-style: italic;
        color: $silver-gray;
      }

      .validation-error {
        margin-top: -15px;
        margin-bottom: 10px;
        color: $red;
      }
    }

    .group-title {
      font-size: 16px;
      color: $dark-gray;
      display: flex;
      justify-content: space-between;
      background: $snow-gray;
      padding: 15px 10px;
      font-weight: 600;

      span {
        display: flex;
        align-items: center;

        svg {
          fill: $light-gray;
          margin-right: 5px;
        }
      }

      .group-menu-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
      }
    }
  }
}

div.creation-generic-dropdown-menu {
  max-width: 596px;
  width: 100%;

  li.react-autosuggest-input-menu-item {
    > div,
    .text-field-wrapper,
    .text-field {
      width: calc(100% + 16px);
    }
  }
}

div.settings,
div.automation-creation-details,
div.targeting-group-preset-popup {
  div.creation-generic-dropdown-button {
    border-radius: initial;
    border: none;
    border-bottom: 2px solid #d6d6d6;
    max-width: initial;
    min-width: initial;
    margin-right: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 400;
    position: relative;
  }

  div.creation-generic-dropdown-borderless-button {
    display: inline-flex;
    border: none;
    margin-bottom: 0;
    padding: 0;
    height: 22px;
    min-width: 50px;
  }

  div.include-browsers-button {
    min-width: 30px;
  }
}

div.settings,
div.automation-creation-details,
.experimental-bulk-creation,
.targeting-group-preset-popup {
  width: 100%;

  div.bid-budget-row {
    margin-bottom: 5px;
  }

  div.creation-generic-dropdown-button {
    color: rgba(0, 0, 0, 0.54);

    > svg.arrow {
      top: 6px;
      left: initial;
    }

    &.selected {
      border-bottom: 2px solid $blue;
      color: $dark-gray;
    }

    &.darker-text {
      color: $dark-gray;
    }
  }

  .creation-generic-dropdown-label {
    color: $blue;
    font-size: 12px;
    margin-top: 14px;
  }

  .allow-multiple-countries {
    cursor: pointer;
    margin-top: 4px;
    display: flex;
    align-items: center;

    > span:last-child {
      color: $gray;
      font-size: 13px;
      margin-left: -2px;
    }
  }

  .loading-text-field {
    display: flex;
    height: 64px;
    align-items: flex-end;
  }
  .status-field {
    display: flex;
    padding-bottom: 3px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    color: #555;
    font-family: "Open Sans";
    margin-top: 18px;
    .status-label {
      font-size: 16px;
      font-weight: 600;
    }
    .status-switch {
      display: flex;
      align-items: center;
      gap: 7px;
      .status-text {
        color: #5b5b5b;
        font-size: 13px;
      }
    }
  }

  .text-field {
    display: flex;
    flex-direction: column;
    position: relative;
    &.bid-budget-field {
      padding-right: 20px;
      width: 100px;
      &.disabled {
        opacity: 0.4;
      }
    }

    svg {
      &.arrow {
        top: 40px;
        right: 2px;
        position: absolute;
        pointer-events: none;
        height: 20px;
        width: 20px;
        @include rounded-arrow($light-gray);
      }
    }

    .text-field-color {
      color: $blue;
    }

    .text-field-line {
      color: $dark-gray;

      &.selected:before {
        border-bottom: 2px solid $blue;
      }

      &:before {
        border-bottom: 2px solid #d6d6d6;
        &:hover {
          border-bottom: none;
        }
      }
      &:after {
        border-bottom: 2px solid $blue;
      }
    }

    &.animated {
      max-height: 0px;
      overflow: hidden;
      transition: max-height 0.3s;
      visibility: hidden;

      &.visible {
        max-height: 120px;
        visibility: visible;

        &.fit-content {
          max-height: 600px;
        }
      }
    }

    &.error {
      .text-field-color,
      .creation-generic-dropdown-label {
        color: $red;
      }

      .text-field-line {
        &:before {
          border-bottom: 2px solid $red;
        }

        &:after {
          border-bottom: 2px solid $red;
        }
      }

      .creation-generic-dropdown-button {
        border-color: $red;
      }
    }

    &.warning {
      .text-field-color {
        color: $gold;
      }

      .text-field-line {
        &:before {
          border-bottom: 2px solid $gold;
        }

        &:after {
          border-bottom: 2px solid $gold;
        }
      }
    }

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .creation-select-wrapper {
    width: 100%;
  }

  .creation-select {
    background: none;

    &.unselected {
      color: rgba(0, 0, 0, 0.54);
    }
  }

  .tags-wrapper {
    position: relative;
    margin-top: 18px;
    overflow: hidden;

    svg.arrow {
      @include rounded-arrow($light-gray);
      top: 18px;
      right: 2px;
      position: absolute;
      pointer-events: none;
      height: 20px;
      width: 20px;
      fill: $light-gray;
    }

    .tags-button {
      padding: 0;
      width: 100%;
      min-width: 100%;
      border: none;
      border-radius: 0;
      margin-top: 16px;
      margin-bottom: 8px;
      border-bottom: 2px solid #d6d6d6;

      &.selected {
        border-bottom: 2px solid $blue;
      }

      .tags-header {
        width: 100%;
        font-size: 16px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.54);

        &.selected {
          color: $dark-gray;
        }
      }

      svg {
        display: none;
      }
    }
  }

  .creation-select-icon {
    display: none;
  }

  .chips-container {
    .chip-item {
      @include chip-item(whitesmoke, #d1d1d1);
    }
    .clear-tags {
      opacity: 0;
      transition: opacity 0.2s;
      color: $light-gray;
      text-decoration: underline;
      font-size: 13px;
      margin-left: 2px;
      margin-bottom: 5px;
    }

    &:hover {
      .clear-tags {
        opacity: 1;
      }
    }

    .remove-all-chips {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $light-gray;
      font-size: 13px;
      margin-top: 5px;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .no-provider-accounts-message {
    display: flex;
    white-space: pre-line;
    flex-direction: column;
    align-items: center;
    font-size: 13px;
    color: $gray;
    margin-top: 60px;
    margin-bottom: 40px;

    .icon {
      margin-bottom: 10px;
    }
  }
}

div.creation-dropdown {
  @extend .default-menu;
  max-height: 210px;

  &.countries-dropdown,
  &.languages-dropdown {
    max-height: 300px;

    ul {
      padding-top: 0;
    }

    .react-autosuggest-input-wrapper {
      background: $white;
      position: sticky;
      padding: 12px 16px;
      top: 0;
      left: 0;
      right: 0;
      z-index: 1;
      height: initial;

      .react-autosuggest-input {
        display: flex;
        align-items: center;
        border-radius: 20px;
        border: 2px solid $border-gray;
        height: 28px;
        width: 100%;

        input {
          font-size: 13px;
          color: $dark-gray;
          font-weight: 600;
        }

        .react-autosuggest__container {
          .text-field-wrapper {
            .search-icon {
              width: 14px;
              height: 14px;
            }
          }
        }
      }

      .react-autosuggest__container {
        flex: 1;

        .text-field-wrapper {
          justify-content: space-between;

          .search-icon {
            height: 12px;
            width: 12px;
            margin-right: 5px;
          }

          .text-field {
            flex: 1;

            .text-field-line {
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  &.funding-sources-dropdown {
    max-height: 218px;

    &.has-sticky-footer {
      > ul {
        padding-bottom: 0;
      }
    }

    .default-menu-item-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  input[type="checkbox"] {
    margin-right: 6px;
  }

  li {
    @extend .default-menu-item;
  }

  .subsection-header {
    margin: 10px 16px 2px 16px;
    color: $light-gray;
    font-size: 12px;
    font-weight: 400;
  }

  .refresh-btn {
    color: $blue;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    font-weight: 400;
    position: sticky;
    bottom: 0;
    background: white;
  }
}

.central-wrapper {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-self: center;

  .title {
    @include title-font();
    font-size: 36px;
    line-height: 0;
    color: $dark-gray;
    margin-top: 75px;
    margin-bottom: 45px;
  }

  .step-header {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: 60px;

    .sub-title {
      color: $gray;
      font-size: 15px;
      font-weight: 400;
    }
  }

  .step-content {
    @include default-box-shadow();
    background-color: $white;
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
    justify-self: center;
    border-radius: 12px 12px 0 0;
    width: 880px;
    min-height: calc(100vh - 184px);
    padding-bottom: 40px;

    button.next-button {
      width: 100%;
      height: 50px;
      border-radius: 25px;
    }

    &.no-padding-bottom {
      padding-bottom: 0;
    }

    .campaign-creation-sources {
      .campaign-creation-source {
        &.selected {
          .source {
            padding-bottom: 0px;
          }
          overflow: auto;
        }

        .source {
          display: flex;
          flex-shrink: 0;
          margin-bottom: 0;
          padding: 15px 20px;
          align-items: center;
          font-size: 14px;
          font-weight: 600;
          transition: padding 0.3s ease-out;
        }

        .source-settings {
          display: flex;
          flex-shrink: 0;
          overflow: hidden;
          padding-left: 50px;
          padding-right: 50px;
          padding-bottom: 50px;
          flex-direction: column;
          visibility: hidden;

          &.open {
            justify-content: flex-start;
            visibility: visible;
          }
          .settings {
            .article-url-input {
              position: relative;
              input {
                padding-right: 50px;
              }
            }
            .assets-url {
              position: absolute;
              right: 0;
              bottom: 15px;
              text-transform: uppercase;
            }
          }
        }
      }

      .duplication-campaign-item {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: $dark-gray;
        text-align: center;
        margin-top: 15px;
        margin-bottom: 15px;

        .light-text {
          font-weight: 200;
        }
      }
    }

    .warning-container {
      margin-top: 40px;
      margin-bottom: -15px;

      svg {
        height: 33px;
        width: 39px;
      }
    }

    .loader-container {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .automation-creation-details {
      display: flex;
      flex-shrink: 0;
      overflow: hidden;
      padding-left: 100px;
      padding-right: 100px;
      padding-bottom: 0;
      flex-direction: column;
    }
  }

  .creation-wizard-navigation {
    display: flex;
    justify-content: center;
    margin: 50px auto 0;

    .back-button {
      flex-basis: 100px;
      border-radius: 25px;
      margin: 0 5px;
    }

    .next-button {
      max-width: 530px;
      margin: 0 5px;
    }

    &.settings-navigation {
      flex-wrap: wrap;

      .next-button,
      .cancel-wizard {
        flex-basis: unset;
        width: 100%;
      }

      .save-automation-button {
        width: 530px;
      }

      .cancel-wizard {
        display: flex;
        font-size: 14px;
        color: $light-gray;
        margin-top: 7px;
        justify-content: center;
      }
    }

    &.column-buttons {
      .next-button {
        margin: 0;
      }
    }
  }

  .launch {
    .launch-boxes {
      width: 700px;
    }

    .launch-box {
      display: flex;
      font-size: 14px;
      padding: 20px 0;
      border-top: 1px solid $border-gray;
      color: $dark-gray;

      &.campaigns-summary {
        padding-bottom: 0;
      }

      &.first {
        padding-top: 0;
        border-top: none;

        span.switch {
          width: 18px;
          height: 11px;
          margin-top: 6px;

          .button-base:before {
            content: "";
          }

          .icon {
            width: 7px;
            height: 7px;
            left: 2px;

            &.extended-icon + input {
              width: 130px;
            }
          }
        }
      }

      .campaign-item {
        line-height: 28px;
      }

      .row-title {
        flex-basis: 120px;
        flex-shrink: 0;
      }

      .text {
        flex-grow: 1;
        word-break: break-word;
      }

      .icon {
        width: 20px;
        flex-shrink: 0;
        height: 20px;
        justify-content: center;
        align-items: center;
        display: flex;
      }

      .os-icon {
        height: 16px;
        margin-left: 4px;
        margin-right: 2px;
        position: relative;
        top: 1px;
      }
    }

    .creatives-group-box {
      display: flex;
      font-size: 14px;
      padding: 0 0 20px 0;
      border-top: 1px solid $border-gray;
      color: $dark-gray;

      &.first {
        padding-top: 0;
        border-top: none;
      }

      .creatives-group-static {
        padding-top: 20px;
      }
    }

    .copy-to-clipboard {
      display: flex;
      justify-content: flex-end;
      margin: 13px 0 19px 0;
      color: $light-gray;
      font-size: 11px;

      svg {
        top: 2px;
        right: 4px;
        width: 10px;
        height: 13px;
        position: relative;
      }
    }

    .launch-button-container {
      position: relative;
      flex-grow: 1;
      max-width: 540px;

      .launching {
        background: $white;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }
  }
}

.central-wrapper .step-content,
.experimental-bulk-creation,
.targeting-group-preset-popup {
  .validation-error {
    display: flex;
    align-items: flex-start;

    svg {
      flex-shrink: 0;
      margin-top: 4px;
    }

    .text {
      margin-left: 8px;
      font-size: 13px;
      color: $red;
    }

    .summary-text {
      font-size: 13px;
      color: $red;
    }
  }

  .validation-warning {
    display: flex;
    align-items: flex-start;

    svg {
      flex-shrink: 0;
      margin-top: 4px;

      .warning-icon-small-svg {
        fill: $gold;
      }
    }

    .text {
      margin-left: 8px;
      font-size: 13px;
      color: $gold;
    }
  }

  .conversion-events-selection {
    @extend .general-text;
    @include clickable();
    height: 100%;
    display: flex;
    align-items: center;
    max-width: 148px;

    > span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .arrow {
    width: 16px;
    height: 16px;

    @include rounded-arrow(#c2c2c2);
  }

  .platform {
    display: flex;
    align-items: center;
    color: $gray;
    font-size: 15px;
    margin-bottom: 0;
    margin-left: 11px;
    font-weight: 400;

    &.select-all {
      margin-left: 0;
    }
  }

  .platforms {
    display: flex;
    flex-direction: column;
    font-size: 13px;
    margin-top: 25px;
    margin-bottom: -15px;

    .validation-error {
      padding-top: 6px;
    }

    .field-title {
      font-size: 16px;
      color: $dark-gray;
      margin-bottom: 10px;
    }
  }

  .multi-selection {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    align-items: flex-start;
    margin-bottom: 12px;
    font-weight: 600;

    &:first-child {
      .platform {
        margin-top: 0;
      }
    }

    .platform-settings {
      height: 0;
      max-height: 0;
      min-height: 0;
      margin-top: 0;
      display: flex;
      overflow: hidden;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      transition: max-height 0.25s ease-out, min-height 0.25s ease-in, margin-top 0.25s ease-in, padding 0.25s ease-in;
      width: 100%;
      background: whitesmoke;
      border-radius: 10px;
      padding: 0 28px;

      &.open {
        min-height: 54px;
        max-height: 400px;
        height: fit-content;
        margin-top: 6px;
        padding: 16px 28px;
        transition: max-height 0.2s ease-in, min-height 0.2s ease-in, margin-top 0.2s ease-in, padding 0.2s ease-in;
      }

      .sub-platforms {
        display: flex;
        flex-direction: row;
        margin-left: -2px;
      }

      > div {
        margin-bottom: 18px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .browsers-note {
        margin-top: 5px;
        color: $light-gray;
      }
    }
  }

  .sub-section-item {
    display: flex;
    color: $gray;
    margin-bottom: 0;
    margin-left: 20px;
    white-space: nowrap;

    &.disabled {
      opacity: 0.5;
    }

    > span {
      margin-right: 6px;
    }

    &:first-child {
      margin-left: 0;
    }
  }
}

div.creative-multiline {
  > div:first-child {
    padding: 0;
  }
}

div.source-platform-dropdown {
  min-width: 104px;
  font-size: 14px;

  li.sub-section-item {
    padding: 2px 16px;

    input {
      margin-right: 5px;
      height: 12px;
      width: 12px;
    }

    span {
      color: $dark-gray;
      font-size: 13px;
      font-weight: 400;
    }

    label {
      margin-bottom: 0;
    }
  }
}

div.conversion-popup {
  max-height: 335px;
  max-width: 350px;

  > ul {
    padding: 0;
  }

  .react-autosuggest-input-menu-item {
    .react-autosuggest__container {
      flex: 1;

      .text-field {
        flex: 1;
      }
    }
  }
}

div.call-to-action-popup {
  max-height: 198px;

  > ul {
    padding: 0 0 8px;
  }
}

div.conversion-popup,
div.account-popup,
div.call-to-action-popup {
  &.account-popup,
  &.call-to-action-popup {
    max-width: 210px;
  }

  .react-autosuggest-input-menu-item {
    position: sticky;
    top: 0;
    z-index: 1;
    background: $white;
    padding-top: 8px;
  }

  div.text-field-wrapper {
    div.text-field {
      border: 2px solid $border-gray;
      border-radius: 20px;
      height: 28px;

      input.react-autosuggest__input {
        height: 30px;
        width: 140px;
        @extend .general-text;
        color: $dark-gray;
        padding: 0 8px;
        outline: none;
        border: none;

        &::placeholder {
          opacity: 0.5;
          color: $dark-gray;
        }
      }

      > div {
        padding-right: 20px;
        height: 100%;

        input[type="text"] {
          height: 100%;
        }
      }
    }
  }

  li {
    &.react-autosuggest-input-menu-item {
      height: 40px;
      padding-left: 14px;

      &:hover {
        background-color: white;
      }
    }

    > span,
    .default-menu-item-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  svg.search-icon,
  svg.clear-filter-text {
    position: relative;
    right: 26px;
  }
}

div.crop-creative-popup {
  padding: 25px 50px 40px;
  overflow: visible;
  box-shadow: none;
  background-color: white;
  max-width: 719px;
  min-width: 300px;
  border-radius: 10px;
  height: initial;
  max-height: initial;
  align-self: initial;
  margin: auto;

  .exit-button {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  .source-selection {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;

    .source-tab {
      display: flex;
      align-items: center;
      color: $gray;
      font-size: 14px;
      margin: 0 10px;
      padding-bottom: 2px;
      display: flex;
      align-items: center;
      border-bottom: 2px solid transparent;

      &.selected {
        color: $blue;
        border-bottom: 2px solid $blue;
      }

      .check-mark {
        margin-right: 6px;
      }
    }
  }

  .ratio-selection {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;

    .ratio-button {
      margin-left: 10px;
      min-width: 100px;

      &:first-child {
        margin-left: 0;
      }

      .ratio-icon {
        width: 15px;
        height: 15px;
        border: 2px solid $light-gray;
        border-radius: 3px;
        margin-right: 5px;
        position: relative;

        &:before {
          position: absolute;
          background: $light-gray;
          content: " ";
        }
      }

      &.landscape {
        .ratio-icon {
          &:before {
            top: 3px;
            right: 0;
            bottom: 3px;
            left: 0;
          }
        }
      }

      &.square {
        .ratio-icon {
          &:before {
            top: 3px;
            right: 3px;
            bottom: 3px;
            left: 3px;
          }
        }
      }

      &.selected {
        .ratio-icon {
          border-color: $white;

          &:before {
            background-color: $white;
          }
        }
      }

      &:disabled {
        &.selected {
          color: $white;
        }
      }
    }

    &.selected {
      .ratio-icon {
        border-color: $white;
      }
    }
  }

  .dialog-container {
    flex: auto;

    .crop-title {
      @include title-font();
      color: $dark-gray;
      font-size: 24px;
      margin-bottom: 20px;
      text-align: center;
    }

    .error {
      margin-top: 10px;
      font-size: 13px;
      color: $red;
      display: flex;
      align-items: flex-start;

      .icon {
        width: 20px;
        flex-grow: 0;
        margin-right: 8px;
      }
    }

    .ReactCrop--disabled {
      cursor: not-allowed;

      &:hover {
        opacity: 0.8;
      }
    }

    .buttons-container {
      margin-top: 20px;

      button {
        height: 50px;
        border-radius: 25px;
      }

      .done {
        width: 100px;
      }

      .save-crop {
        margin-left: 12px;
        width: 400px;

        &.disabled-save-crop {
          color: white;
        }
      }
    }

    .crop-dimensions {
      font-size: 11px;
      color: $light-gray;
      text-align: center;
      font-weight: 600;
      margin-top: 4px;
    }

    .loader-container {
      min-height: 200px;
    }
  }
}

div.generic-dropdown-popup {
  &.tags-menu {
    width: 460px;
    max-width: none;
    margin-top: 3px;

    &.campaign-popup-tags-menu {
      width: 275px;
    }

    div.react-autosuggest__container {
      width: 100%;

      div.text-field-wrapper {
        div.text-field {
          width: 100%;
          height: 22px;
          font-weight: 400;
          margin-right: -16px;
        }

        input.react-autosuggest__input {
          min-width: 90%;
          padding: 0 13px;
          font-weight: 400;
        }
      }

      svg.search-icon {
        display: flex;
        right: 12px;
        height: 12px;
        width: 12px;
      }
    }
  }
}

div.generic-dropdown-popup {
  &.audiences-dropdown-menu {
    width: 100%;
    max-width: 380px;

    .text-field-wrapper {
      width: calc(100% + 14px);

      .text-field {
        width: 100%;
      }
    }

    .react-autosuggest__container {
      flex: 1;
    }
  }
}

.experimental-bulk-creation {
  .creation-select-wrapper {
    margin-top: 0;
    margin-bottom: 12px;

    .text-field-line {
      margin-top: 0;
    }
  }

  .text-field svg.arrow {
    top: 10px;
  }
}

.upload-creatives * {
  height: 100%;
  width: 100%;

  .upload-creatives-text-align {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
