.auto-suggest {
  .auto-suggest-text-field {
    display: flex;
    align-items: flex-start;
    padding: 6px 0;

    input {
      color: #cdcdcd !important;
    }

    svg {
      height: 1px;
      stroke: $admin-black;
      fill: $admin-black;
    }

    div:last-child {
      > div {
        //>svg {
        //  height: 1px;
        //  stroke: $admin-black;
        //  fill: $admin-black;
        //}
      }

      > span {
        background-color: $admin-black;
      }
      div:last-child {
        //svg {
        //  height: 1px;
        //  stroke: $admin-black;
        //  fill: $admin-black;
        //}
      }
    }
  }

  .suggestions-container {
    display: flex;
    > div {
      padding-bottom: 1px;
    }

    > div:last-child {
      padding: 0;
      margin: 0;
    }
  }

  .no-options {
    color: $light-gray;
  }
}
