.settings-page {
  .app-current-version {
    margin-top: -40px;
    span {
      font-size: 11px;
      color: $gray;
    }
  }

  .settings-navigation-menu {
    font-size: 16px;
    color: $gray;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 12px;
    margin-bottom: 40px;
    margin-top: 50px;

    .settings-navigation-menu-item {
      margin-right: 24px;
      color: $gray;
      text-decoration: none;

      &.selected {
        color: $blue;
      }
    }
  }

  body {
    background-color: #f3f5f9;
  }

  .wrapper {
    display: flex;
    position: relative;
    ul {
      padding: 0;
    }

    .sidebar {
      width: 200px;
      height: 100%;
      position: relative;
      font-size: 14px;
      color: #666;
    }

    .sidebar ul li {
      @include clickable();
      padding: 0 0 15px 0;
      list-style-type: none;
      color: #666;

      &.disabled {
        display: none;
      }
      &:hover {
        background-color: inherit;
        color: #333;
      }
      &.selected {
        font-weight: 600;
        color: #2e92ea;
      }
    }

    .main-content {
      width: 100%;
      max-width: 1200px;
      padding: 0;
      overflow: auto;
      line-height: 22px;
      margin-bottom: 20px;
    }
  }

  .settings-subsection {
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    align-self: stretch;
    border-radius: 7px;
    border: 2px solid #edeced;
    margin-bottom: 20px;

    .settings-subsection-header {
      color: #333;
      font-family: "Open Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      .platform-icon {
        width: 16px;
        height: 16px;
        margin-right: 10px;
      }
    }
    .settings-subsection-user-name {
      color: #333;
      font-family: "Open Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      &.error {
        color: $red;
      }
    }

    .settings-subsection-content {
      display: flex;
      padding: 12px 0;

      .loader-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 16px;
      }

      .settings-name {
        padding-right: 10px;
        font-weight: 600;
      }
    }

    &.box-design {
      border: 2px solid #e8e8e8;
      border-radius: 6px;
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.04);
      margin-bottom: 30px;

      .settings-subsection-header {
        color: $gray;
        font-size: 16px;
        font-weight: 700;
        font-style: initial;
        background: initial;
        border-radius: initial;
        border: initial;
        padding: 28px 12px 12px;
      }

      .settings-subsection-content {
        padding: 12px 24px 24px 24px;

        .generic-dropdown-selection {
          margin-bottom: 0;
        }
      }
    }

    .settings-section-loading-indicator {
      width: 70px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .provider-with-bids-matrix {
    padding: 20px 20px 20px 12px;
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    font-size: 14px;
    position: relative;

    .country-override {
      text-align: right;
    }

    .add-country {
      @include clickable();
      position: absolute;
      right: 14px;
      top: 50%;
      transform: translateY(-50%);
      background-color: whitesmoke;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 34px;
      height: 34px;

      .plus-icon {
        width: 10px;
        height: 10px;

        .plus-icon-svg {
          fill: $light-gray;
        }
      }
    }

    .remove-country {
      @include clickable();
      position: absolute;
      right: 25px;
      top: 25px;
      width: 8px;
      height: 8px;

      .x-icon-svg {
        fill: $light-gray;
      }
    }

    .country-selection {
      font-size: 13px;
      color: $gray;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .flag {
        margin-right: 6px;
      }

      .down-arrow {
        @include material-ui-icon-stroke($light-gray);
        fill: $light-gray;
        width: 16px;
        margin-left: 2px;
        position: relative;
        top: 1px;
      }
    }

    &:last-child {
      border-bottom: none;
    }
  }

  .settings-value-box {
    display: flex;
    font-size: 13px;
    color: $gray;
    margin-top: 10px;
    justify-content: space-between;

    &:first-child {
      margin-top: 0;
    }
  }

  .settings-switch-row {
    margin-top: 10px;

    .switch {
      margin-right: 4px;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  .save-button {
    width: 300px;
    height: 50px;
    border-radius: 25px;
    font-size: 16px;
  }

  .settings-network {
    font-weight: 700;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 152px;
    height: 36px;
    border: 2px solid #d1d1d1;
    border-radius: 18px;
    color: $gray;
    font-size: 14px;
    padding: 0 14px;

    .down-arrow {
      fill: #d1d1d1;
      stroke: #d1d1d1;
      stroke-linejoin: bevel;
      width: 16px;
      height: 16px;
      margin: 3px 0 0 3px;
    }
  }

  .refresh-button {
    height: 30px;
    min-width: 80px;
    color: white;
    background-color: $blue;

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  div.refresh-message {
    padding: 6px 6px 6px 0;

    span:first-child {
      padding-left: 8px;
    }
    span:last-child {
      padding-left: 5px;
      color: $light-gray;
      font-weight: 300;
    }
  }

  .facebook-ad-accounts-container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 15px;
    align-self: stretch;
    .connect-facebook-ad-accounts-button {
      border-radius: 40px;
      border: 2px solid #0f86e6;
      display: flex;
      padding: 5px 20px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      color: #0f86e6;
      text-align: center;
      font-size: 14px;
      background-color: white;
      line-height: 1.5;
    }

    .facebook-ad-accounts-popup-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000;
    }

    .facebook-ad-accounts-popup-container {
      background: white;
      padding: 20px;
      border-radius: 8px;
      width: 400px; /* Adjust as needed */
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
      position: relative;
    }

    .facebook-ad-accounts-popup-close-button {
      position: absolute;
      top: 10px;
      right: 10px;
      background: transparent;
      border: none;
      font-size: 20px;
      cursor: pointer;
    }

    .facebook-ad-accounts-popup-content {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .facebook-ad-accounts-header {
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 18px;
      font-weight: bold;
    }

    .add-facebook-item {
      display: flex;
      justify-content: center;
      font-size: 14px;
      color: $dark-gray;
      flex-direction: column;
      .ad-account-ids-box {
        border: 1px solid grey;
        padding: 10px;
        gap: 10px;
        margin-bottom: 10px;
      }
    }
  }

  .provider-integration-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: -10px;

    .facebook-connect-button {
      background-color: #4267b2;
      font-size: 13px;
      min-width: 155px;
    }

    .pinterest-connect-button {
      background-color: #e60022;
      font-size: 13px;
      min-width: 155px;
    }

    .generic-dropdown-selection {
      margin-left: 10px;
      margin-top: 10px;
    }

    .provider-integration-message {
      margin-left: 16px;
      color: $inactive-color;
      font-size: 13px;
      font-weight: 600;
    }
  }

  .taboola-blocked-subsources {
    .subsources-subsection-content {
      padding: 12px 24px 8px 24px;
    }

    .sub-sources-multi-line {
      .input-taboola-sub-sources {
        font-size: 15px;
        width: 200%;
        padding: 5px 10px 10px 10px;

        &:before,
        &:after {
          border: none;
        }
      }

      textarea {
        border: 1px solid #bebaba;
        border-radius: 3px;
        padding: 18px 14px;
      }
    }

    .taboola-sub-sources-buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 7px;
      padding-right: 2px;
    }

    .save-button,
    .add-button {
      width: 67px;
      height: 30px;
      border-radius: 25px;
      font-size: 13px;
      max-width: 50%;
    }

    .add-button {
      margin-left: 10px;
    }

    .save-button {
      margin-right: 12px;
    }

    .save-loader {
      color: white;
    }

    .list {
      padding-left: 33px;
      padding-right: 17px;
    }

    .header {
      justify-content: space-between;
      margin-right: 16px;
    }

    .list-rows {
      overflow-y: auto;
      max-height: 390px;
      padding-right: 16px;
    }

    .list-row {
      display: flex;
      border-bottom: 1px solid #efefef;
      min-height: 40px;
      align-items: center;
    }

    .list-cell {
      flex-grow: 1;
    }
  }

  .country-groups,
  .facebook-pages,
  .taboola-blocked-subsources,
  .facebook-ad-accounts,
  .targeting-group-presets {
    color: $gray;
    font-size: 13px;

    div.ad-accounts-multi-line {
      > div:first-child {
        font-size: 15px;
        width: 200%;
        padding: 10px;
      }

      textarea {
        border: 1px solid #bebaba;
        border-radius: 3px;
        padding: 18px 14px;
      }

      .input-facebook-ad-accounts {
        &:before,
        &:after {
          border: none;
        }
      }
    }

    .facebook-pages-site {
      margin-top: 40px;

      .site-name {
        font-size: 14px;
        color: $dark-gray;
      }
    }

    .add-facebook-item {
      display: flex;
      justify-content: center;
      font-size: 14px;
      color: $dark-gray;
      flex-direction: column;
      .ad-account-ids-box {
        border: 2px solid black;
      }
      .generic-dropdown-selection {
        margin-left: 10px;
      }

      .save-button {
        width: 67px;
        height: 30px;
        font-size: 13px;
      }

      input,
      .form-label-field {
        color: $gray;
        font-size: 13px;
      }

      input {
        border-bottom: 1px solid $gainsboro-gray;
        width: 180px;
      }

      form {
        margin-top: 10px;

        > div,
        > span {
          margin-right: 10px;
        }
      }

      .validation-error {
        margin-top: 10px;
        font-size: 13px;
        color: $red;
      }

      .loader-ad-accounts {
        margin-top: 7px;
        margin-left: 30px;
      }

      .connect-ad-accounts-button {
        margin-left: 10px;
        width: 120px;
        margin-top: 7px;
        font-size: 13px;
        border-radius: 25px;

        &.disabled {
          opacity: 0.5;
          pointer-events: none;
        }
      }
    }

    .create-group-button {
      font-size: 13px;
      font-weight: 400;
    }

    .list {
      margin-bottom: 12px;
      position: relative;

      .loading-mask {
        background: $white;
        opacity: 0.5;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .header {
      display: flex;
      border-bottom: 2px solid $border-gray;
      font-weight: 600;
      height: 40px;
      align-items: center;
    }

    .country-group,
    .facebook-page,
    .list-row {
      display: flex;
      border-bottom: 1px solid $border-gray;
      min-height: 40px;
      align-items: center;
    }

    .name,
    .small-column {
      flex-grow: 1;
      width: 200px;
    }

    .countries {
      flex-grow: 1;
      width: 600px;
    }

    .page-language-button {
      border-radius: initial;
      border: none;
      border-bottom: 1px solid $gainsboro-gray;
      width: 180px;
      max-width: initial;
      min-width: initial;
      margin-right: 0;
      padding: 0;
      font-size: 13px;
      font-weight: 400;
      position: relative;
    }

    .page-language-header {
      padding-bottom: 3px;
    }

    .page-id {
      flex-grow: 1;
      width: 165px;
      word-wrap: break-word;
    }

    .page-name {
      flex-grow: 40;
      width: 300px;
      word-wrap: break-word;
    }

    .page-language {
      flex-grow: 100;
      width: 200px;
    }
    .page-instagram-actor-id {
      flex-grow: 1;
      width: 200px;
      word-wrap: break-word;
    }

    .actions {
      flex-grow: 0;
      flex-shrink: 0;
      width: 250px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      > div {
        margin-left: 30px;
        display: flex;
        align-items: center;

        &:hover {
          color: $dark-gray;
          transition: color 0.3s;

          .edit-icon,
          .delete-icon,
          .duplicate-icon {
            path {
              fill: #8d8d8d;
              transition: fill 0.3s;
            }
          }
        }
      }

      .edit-icon {
        width: 13px;
        height: 13px;
        color: $silver-gray;
        margin-right: 4px;
      }

      .delete-icon {
        width: 9px;
        height: 9px;
        margin-right: 4px;

        .x-icon-svg {
          fill: $silver-gray;
        }
      }

      .duplicate-icon {
        margin-right: 4px;
      }
    }
  }
}

div.settings-save-dialog {
  width: 500px;
  border-radius: 10px;
  padding: 40px;
  font-size: 15px;
  color: $dark-gray;
  box-shadow: none;

  .settings-save-dialog-title {
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 20px;
  }

  .settings-save-dialog-buttons {
    margin-top: 50px;

    button,
    .settings-saving {
      width: 120px;
      align-items: center;
      justify-content: center;
    }

    .settings-save-dialog-cancel {
      font-size: 13px;
      color: $gray;
      margin-left: 12px;
    }
  }
}

div.main-commerce {
  .commerce-box {
    display: flex;
    padding: 40px;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    align-self: stretch;
    border-radius: 7px;
    border: 2px solid #edeced;
    margin-bottom: 20px;
    .add-ecom-file {
      display: flex;
      height: 80px;
      padding: 10px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      align-self: stretch;
      border-radius: 10px;
      background: #f2f2f2;
      .add-ecom-text {
        color: #8f8f8f;
        text-align: center;
        font-size: 14px;
        font-weight: 600;
      }
      .add-ecom-icon {
        display: flex;
        width: 34px;
        height: 34px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 100px;
        background: #d7d7d7;
        .plus-icon {
          color: #fff;
          text-align: center;
          font-family: "Open Sans";
          font-size: 22px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
    }
    .commerce-sync-title {
      color: #666;
      gap: 10px;
      font-family: "Open Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .commerce-sync-text {
      color: #666;
      font-size: 14px;
      font-weight: 400;
    }
  }
}

div.settings-network-dropdown {
  &.generic-dropdown-popup {
    min-width: 152px;
    max-height: 69vh;
  }
}

div.country-selection-menu {
  .flag {
    margin-right: 6px;
  }
}

div.country-group-popup,
div.targeting-group-preset-popup {
  box-shadow: none;
  width: 500px;
  padding: 35px 70px 50px;
  position: relative;

  .account-type-switch-row {
    margin-top: 30px;
    margin-bottom: 25px;
    font-size: 15px;

    .account-type-title {
      margin-left: 8px;
    }

    .account-type-text {
      color: $gray;
      margin-left: 8px;
    }
  }

  .account-types-selection {
    margin-top: 15px;
  }

  .exit-button {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  .title {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 30px;
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;

    .create-country-group-button,
    .create-targeting-group-preset-button {
      width: 100px;
      margin-right: 20px;
      height: 30px;

      &:disabled {
        color: $white;
      }
    }

    .cancel-button {
      font-size: 13px;
    }
  }

  .chips-container {
    .chip-item {
      display: inline-flex;
      background-color: whitesmoke;
      margin: 0 5px 5px 0;
      color: $dark-gray;
    }
  }

  .delete-icon {
    @include material-ui-icon-stroke(#d1d1d1);
    height: 12px;
    fill: #d1d1d1;

    path:first-child {
      stroke-width: 2px;
    }

    &:hover {
      @include material-ui-icon-stroke($gray);
      fill: $gray;
    }
  }

  .text-field {
    position: relative;

    > svg {
      &.arrow {
        bottom: 16px;
        right: 2px;
        position: absolute;
        pointer-events: none;
        height: 20px;
        width: 20px;
        @include rounded-arrow($light-gray);
      }
    }
  }

  .text-field-line {
    color: $dark-gray;

    input {
      width: 100%;
    }

    &:before {
      border-bottom: 2px solid #d6d6d6;
      &:hover {
        border-bottom: none;
      }
    }
    &:after {
      border-bottom: 2px solid $blue;
    }
  }

  .creation-select {
    background: none;

    &.unselected {
      color: rgba(0, 0, 0, 0.54);
    }
  }

  .creation-select-wrapper {
    width: 100%;
  }

  .creation-select-icon {
    display: none;
  }
}

div.targeting-group-preset-popup {
  width: 676px;
  max-width: 676px;

  .text-field {
    display: flex;
    flex-direction: column;
  }

  .loading-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 72px;
  }
}
