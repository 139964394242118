.campaign-delay-popover {
  pointer-events: none;
}

.campaign-delay-popup {
  width: 300px;
  @include default-box-shadow();
  border-radius: 6px;

  .campaign-delay-title-row {
    padding: 12px 23px 8px 23px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .campaign-delay-title {
      color: $dark-gray;
      font-size: 12px;
      font-weight: 700;
    }

    .campaign-delay {
      color: $light-gray;
      font-size: 10px;
      font-weight: 400;
    }
  }

  .campaign-delay-body {
    padding: 0 23px;

    .campaign-delay-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 12px;

      &:nth-last-child(2) {
        margin-bottom: 0;
      }

      svg {
        height: 13px;
        width: 13px;
        margin-right: 8px;
      }

      .delay-time {
        width: 26px;
        color: $gray;
        font-size: 10px;
        font-weight: 400;
      }

      .bar-bg {
        flex: 1;
        height: 13px;
        border-radius: 7px;
        background-color: #f2f2f2;
        display: flex;
        flex-direction: row;
        margin: 0 5px;

        .bar-fill {
          border-radius: 7px;
          background-color: $sky-blue;
          display: flex;
          justify-content: flex-end;
          align-items: center;

          .last-update-time {
            color: white;
            font-size: 9px;
            font-weight: 400;
            margin-right: 5px;
          }
        }
      }
    }
  }
}
