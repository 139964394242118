.profile-page {
  .profile-navigation-menu {
    font-size: 16px;
    color: $gray;
    border-bottom: 2px solid #e0e0e0;
    padding-bottom: 12px;
    margin-bottom: 40px;
    margin-top: 50px;

    .profile-navigation-menu-item {
      margin-right: 24px;

      &.selected {
        color: $blue;
      }
    }
  }

  .profile-personal-details {
    .profile-left-column {
      min-width: 450px;
      padding-right: 40px;

      button.profile-button {
        @extend button.round-button;
        height: 50px;
        border-radius: 25px;
        margin-top: 20px;

        background: $blue;

        &:hover {
          background: lighten($blue, 10);
        }

        &:focus {
          background: darken($blue, 10);
        }
      }
    }

    .profile-right-column {
      padding-left: 40px;

      .user-avatar {
        margin-right: 17px;
        height: 90px;
        width: 90px;
        background-color: transparent;

        svg {
          height: 100%;
        }
      }

      .avatar-title {
        font-weight: 500;
        color: $light-gray;
        font-size: 14px;
        margin: 10px 0;
      }

      .upload-button {
        @include clickable();
        font-weight: 600;
        border: 2px solid #d1d1d1;
        color: $gray;
        background-color: white;
        border-radius: 17px;
        padding: 0 20px;
        font-size: 14px;
        text-transform: none;
        min-height: 30px;
        line-height: 30px;
        box-shadow: none;

        &:disabled,
        &.disabled {
          opacity: 0.5;
        }
      }

      .upload-input {
        display: none;
      }
    }
  }

  .form-label-field {
    color: $light-gray;
  }

  .form-text-field {
    font-weight: 300;
    color: $dark-gray;

    input:focus {
      font-weight: 500;
    }

    &:before {
      border-bottom: 2px solid #d6d6d6;
    }

    &:after {
      border-bottom: 2px solid $blue;
    }

    &.disabled {
      color: $light-gray;
    }
  }
}

div.crop-picture-popup {
  padding: 25px 30px;
  overflow: visible;
  box-shadow: none;
  background-color: white;
  max-height: 454px;
  max-width: 419px;
  min-width: 300px;
  border-radius: 8px;

  .dialog-container {
    flex: auto;

    .crop-title {
      font-weight: 700;
      color: $dark-gray;
      font-size: 15px;
      margin-bottom: 20px;
    }

    .buttons-container {
      margin-top: 20px;

      .save-crop {
        margin-right: 20px;
      }
    }

    .ReactCrop {
      .ReactCrop__crop-selection {
        border-radius: 50%;
      }
    }
  }
}
