.duration-picker {
  width: 215px;
  height: 30px;
  display: flex;
  border-radius: 15px;
  background-color: ghostwhite;

  .duration-picker-item {
    flex: 1;
    border-radius: 15px;
    background-color: transparent;
    color: $dark-gray;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    font-weight: 600;

    &.selected {
      background-color: $blue;
      color: $white;
    }
  }
}
