.page-login {
  display: flex;
  background: $bg-gray;
  height: 100vh;
  min-height: 460px;

  .column {
    color: $red;
    display: flex;
    height: 90%;
    width: 50%;
    flex-direction: column;
    min-width: 630px;

    .header {
      height: 20%;
      @include title-font();
      color: $dark-gray;
      padding: 50px 0 50px 0;
      font-size: 36px;
    }

    .content {
      padding: 0 8% 0 8%;
      background: $white;
      border-radius: 12px 12px 0 0;
      height: 80%;
      width: 100%;
      position: relative;
      @include default-box-shadow();
      @extend .general-text;

      .top-content {
        height: 93%;

        .text-box {
          padding-left: 30px;
          width: 60%;

          .title {
            @include title-font();
            color: $dark-gray;
            height: 100px;
            font-size: 20px;
          }

          .contact-info {
            font-weight: 400;
            padding-top: 16px;
          }
        }
      }
    }
  }

  .login-btn {
    padding-top: 113px;
    width: 40%;
    display: flex;
    justify-content: flex-end;
  }

  .google-login-btn {
    @extend .general-text;
    @include clickable();
    color: white;
    width: 100%;
    background-color: #4285f4;
    height: 44px;
    max-width: 185px;
    border: none;
    text-align: center;
    vertical-align: center;
    font-size: 13px;
    border-radius: 1px;
    box-shadow: rgba(0, 0, 0, 0.25) 0 2px 4px 0;
    padding: 0;

    .sign-in-text {
      margin: 0 8px 0 6px;
    }
  }

  .footer {
    @extend .general-text;
    font-weight: 400;
    color: $light-gray;
    font-size: 13px;
    align-self: center;
    line-height: 60px;
    width: 100%;
    border-top: 1px solid $border-gray;

    .footer-text {
      flex-grow: 1;
    }
  }

  .link {
    @extend .general-text;
    font-weight: 400;
    color: $gray;
    text-decoration: underline;

    &.footer-contact {
      color: $light-gray;
    }
  }
}
