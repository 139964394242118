div.collection-search {
  .collection-react-autosuggest-input {
    @extend .general-text;
    font-weight: 400;
    padding: 0;
    outline: none;
    border: none;
    min-width: 150px;
    width: 180px;

    > div {
      > input {
        @extend .general-text;
        font-weight: 400;
        color: $light-gray;

        &:focus {
          &::placeholder {
            display: none;
            padding-left: 0;
          }
        }

        &::placeholder {
          color: $gray;
          opacity: 1;
          padding-left: 30px;
          transition: padding 1s;
        }
      }
    }
  }

  .react-autosuggest-render-input {
    width: 206px;
    position: relative;
    justify-content: space-between;
  }
}

#search-span {
  z-index: 1;
  position: relative;
  right: 30px;
}

div.collection-render-suggestion-container {
  z-index: 3;
  width: 235px;
  border-radius: 6px;
  max-height: 250px;
  overflow-y: auto;
  position: absolute;
  top: 30px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);

  div.react-autosuggest-menu-item {
    @extend .general-text;
    height: 18px;
    color: $dark-gray;

    > div {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .operators-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-top: 12px;
    margin-bottom: 12px;

    .operator {
      width: calc(50% - 24px);
      font-size: 13px;
      padding: 0 10px;
      background-color: transparent;
      color: $dark-gray;

      &.selected {
        background-color: $border-gray;
      }
    }
  }
}

div.collection-react-autosuggest-input {
  > div {
    padding-left: 6px;
  }
}

.collection-autosuggest-container {
  width: 180px;
  position: relative;
}

.collection-suggestions-container-open {
  position: absolute;
  top: 30px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);

  .operators-container {
    margin-bottom: 0;
  }
}

.collection-suggestion {
  display: block;
}

.collection-suggestions-list {
  margin: 0;
  padding: 0 2px 0 0;
  list-style-type: none;
  color: $dark-gray;
}
