.appbar {
  display: block;
  height: 60px;
  margin-bottom: 40px;
  position: relative;
  background: $white;
  box-shadow: none;
  transition: margin-bottom 300ms ease-in;
}

@keyframes slideInFromTheTop {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.admin-notification {
  height: 40px;
  background-color: #2e92ea;
  color: $white;
  box-shadow: 0 3px 6px rgba(36, 106, 162, 0.15);
  width: 100%;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  transition: height 300ms ease-in;
  overflow: hidden;

  .single-notification {
    height: 40px;
    width: 100%;
    display: flex;
    flex: auto;
    align-items: center;
    justify-content: center;

    .notification-text {
      font-weight: 200;
      font-size: 13px;
    }

    .dismiss-button {
      font-weight: 200;
      font-size: 13px;
      text-decoration: underline;
      margin-left: 20px;
    }
  }
}

.navigation-bar {
  height: 60px;
  background-color: $blue;
  color: $white;
  box-shadow: 0 3px 6px rgba(36, 106, 162, 0.15);
  width: 100%;
  display: flex;
  align-items: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 4;
  min-width: 645px;
  transition: top 300ms ease-in;
  animation-duration: 0.36s; /* the duration of the animation */
  animation-timing-function: ease-out; /* how the animation will behave */
  animation-delay: 0s; /* how long to delay the animation from starting */
  animation-iteration-count: 1; /* how many times the animation will play */
  animation-name: slideInFromTheTop; /* the name of the animation we defined above */

  .campaign-management-menu {
    .campaign-management-menu-button {
      margin-right: 30px;
      color: #d3e8f6;
      font-size: 15px;
      text-decoration: none;
      margin-bottom: 3px;
      text-transform: none;
      &.selected {
        color: $white;
        opacity: 1;
      }
      :hover {
        background-color: $blue;
      }
      .arrow-down {
        margin-left: 5px;
        width: 16px;
        margin-bottom: -1px;
        @include rounded-arrow(#d3e8f6);
      }
    }
    :hover {
      background-color: $blue;
    }
  }

  .row {
    flex-grow: 1;
  }

  #back-home-btn {
    height: 16px;
    stroke-width: 3px;
  }

  #back-home-txt {
    @include clickable();
    font-weight: 600;
    color: white;
    font-size: 14px;
  }

  .logo {
    @include hover-opacity(0.8);
    @include clickable();
    margin-right: 50px;
    margin-left: 3px;
  }

  .user {
    @include clickable();
    flex-direction: row;

    .user-image {
      width: 33px;
      height: 33px;
      position: relative;
      top: 1px;
    }

    .user-details {
      line-height: 16px;

      .user-name {
        font-size: 11px;
        color: #d3e8f6;
      }

      .user-site {
        @include hover-opacity(0.8);
        font-weight: 600;
        font-size: 14px;

        .arrow {
          margin-left: 7px;
          width: 16px;
          margin-bottom: -9px;
          @include rounded-arrow(#d3e8f6);
        }
      }
    }
  }

  .user-settings {
    padding-top: 0;
    color: $white;
  }

  a.navigation-item {
    @include hover-opacity(0.8);
    @include clickable();
    margin-right: 34px;
    color: #d3e8f6;
    font-size: 15px;
    text-decoration: none;
    margin-bottom: 3px;

    &.selected {
      color: $white;
      opacity: 1;
    }
  }
}

div.navigation-menu {
  width: 210px;
  font-size: 14px;
  padding: 0;
  max-height: 338px;
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 0;
    background: transparent; /* make scrollbar transparent */
  }

  div.text-field-wrapper {
    padding-top: 8px;

    div.text-field {
      border: 2px solid $border-gray;
      border-radius: 20px;
      height: 28px;

      input.react-autosuggest__input {
        height: 30px;
        width: 140px;
        @extend .general-text;
        color: $dark-gray;
        padding: 0 8px;
        outline: none;
        border: none;

        &::placeholder {
          opacity: 0.5;
          color: $dark-gray;
        }
      }

      > div {
        padding-right: 20px;
        height: 100%;

        input[type="text"] {
          height: 100%;
        }
      }
    }
  }

  svg.search-icon {
    position: relative;
    right: 26px;
  }

  li {
    padding: 0;

    &.not-link,
    a {
      @extend .general-text;
      width: 100%;
      box-sizing: border-box;
      padding: 4px 16px;
      text-decoration: none;
    }

    &.react-autosuggest-input-menu-item {
      height: 50px;
      padding-left: 14px;

      &:hover {
        background-color: white;
      }
    }
  }

  :last-child {
    &.divider {
      display: none;
    }
  }

  .network-group-divider {
    margin: 0 16px 7px 16px;
  }

  .dropdown-network {
    margin-top: 3px;

    a {
      font-weight: 600;
      color: $blue;
      font-size: 13px;
    }
  }

  .divider {
    border-bottom: 2px solid $border-gray;
    background-color: transparent;
    margin-top: 10px;
  }

  svg {
    &.clear-filter-text {
      position: relative;
      right: 26px;
    }
  }
}

.avatar-shadow {
  border-radius: 50%;
  margin-right: 8px;
  background-color: $blue;
}

.navigation-icon {
  @include hover-opacity(0.8);
  @include clickable();
  width: 18px;
  height: 18px;
  margin: 0 18px 0 10px;
}

.updates-chip {
  font-weight: 600;
  background-color: $white;
  color: $blue;
  font-size: 10px;
  min-width: 14px;
  height: 14px;
  margin-left: -12px;
  position: relative;
  bottom: 6px;
  right: 4px;

  .label {
    padding-right: 4px;
    padding-left: 4px;
  }

  &:hover {
    background-color: white;
  }
}

.navigation-icons-container {
  display: flex;
  flex-direction: row;
  margin: 12px 12px 10px 0;
}

.navigation-tabs,
.navigation-items {
  width: calc(80% - 60px);
  white-space: nowrap;
}
