$datepicker-side-buttons-width: 150px;

.datepicker {
  .current-dates {
    color: $gray;
    font-size: 16px;

    .date-text {
      &:hover {
        color: #333333;
      }
    }

    .dates-title {
      font-weight: 400;
      color: $light-gray;
      font-size: 14px;
    }
  }

  .down-arrow {
    @include material-ui-icon-stroke(#d1d1d1);

    fill: #d1d1d1;
    width: 16px;
    height: 16px;
    margin: 5px 0 0 3px;
  }
}

div.datepicker-popover {
  padding-right: $datepicker-side-buttons-width;
  @include default-box-shadow();
  border-radius: 6px;

  .datepicker-top-inputs {
    position: absolute;
    width: 450px;
    top: 33px;
    left: 0;
    z-index: 1;
    max-height: 0;
    overflow: hidden;
    transition: max-height 400ms;

    &.visible {
      max-height: 30px;
    }

    input {
      background-color: $ghost-white;
      border-radius: 13px;
      width: 99px;
      height: 26px;
      text-align: center;
      border: none;
      font-size: 14px;
      color: $gray;
      margin: 0 60px;
    }
  }

  .datepicker-wrapper {
    &.compact {
      .DayPicker {
        padding-top: 0;
      }
    }
    .DayPickerNavigation {
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 2;
      display: flex;
      justify-content: space-between; /* Ensures the arrows are on opposite sides */
    }

    .DayPickerNavigation_button {
      padding: 0;
      position: relative;
      height: 100%;
      width: 24px;
    }
  }

  .compare-to-datepicker-wrapper {
    max-height: 0;
    overflow: hidden;
    transition: max-height 400ms;
    position: relative;
    z-index: 1;

    &.visible {
      max-height: 200px;
    }

    .DayPicker {
      padding-top: 0;
      margin-top: -37px;
    }

    .CalendarDay {
      &:not(.CalendarDay__blocked_out_of_range) {
        &.CalendarDay__selected_start,
        &.CalendarDay__selected_end {
          background: $purple;

          &.CalendarDay__selected_start:before {
            background: $light-pink;
          }
          &.CalendarDay__selected_end:before {
            background: $light-pink;
          }
        }

        &.CalendarDay__selected_span,
        &.CalendarDay__hovered_span {
          &:not(.CalendarDay__selected_start) {
            &:not(.CalendarDay__selected_end) {
              background-color: $light-pink;
            }
          }
        }

        &.CalendarDay:hover {
          background-color: $light-pink;

          &.CalendarDay__selected_start,
          &.CalendarDay__selected_end {
            background: $purple;
          }
        }
      }
    }
  }

  .calculating-report-size,
  .more-than-maximum-range-confirmation-box {
    top: 0;
    left: 0;
    right: 0;
    height: 0;
    z-index: 4;
    display: flex;
    overflow: hidden;
    position: absolute;
    background: $white;
    align-items: center;
    justify-content: center;
  }

  .calculating-report-size {
    flex-direction: row;

    &.visible {
      height: 100%;
      transition: height 0.2s;
    }

    .text {
      font-size: 14px;
      color: $dark-gray;
      margin-left: 12px;
    }

    .loader {
      color: $sky-blue;
    }
  }

  .more-than-maximum-range-confirmation-box {
    flex-direction: column;
    transition: height 0.3s;

    &.visible {
      height: 100%;
      transition: height 0s;
    }

    .title {
      color: $gray;
      font-size: 18px;
      font-weight: 600;
    }

    .text {
      font-size: 16px;
      margin-top: 10px;
      color: $light-gray;
    }

    .buttons {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .button-loader {
    min-width: 88px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
}

.datepicker-confirmation-buttons {
  text-align: center;
  padding: 12px;
  width: calc(100% + #{$datepicker-side-buttons-width});
  background: $bg-gray;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.DayPicker {
  @import "~react-dates/lib/css/_datepicker.css";
  z-index: 3;
  min-width: 250px;
  transition: padding-top 400ms;
  padding-top: 38px;

  td[class^="CalendarDay"] {
    height: 20px !important;
    line-height: 14px;
    position: relative;
  }

  .CalendarMonth {
    background-color: initial;

    .CalendarMonth_caption {
      font-size: 14px;
      padding: 37px 0 30px 0;
    }
  }

  .DayPickerNavigation_container {
    button {
      background: none;
      border: none;
    }
  }

  .DayPicker {
    border: none;
    box-shadow: none;
  }

  .CalendarDay {
    border: none;
    font-size: 11px;

    &:not(.CalendarDay__blocked_out_of_range) {
      color: $gray;

      &.CalendarDay__selected_span,
      &.CalendarDay__hovered_span {
        &:not(.CalendarDay__selected_start) {
          &:not(.CalendarDay__selected_end) {
            background-color: $light-blue;
          }
        }
      }

      &.CalendarDay__selected_start,
      &.CalendarDay__selected_end,
      &.CalendarDay__selected {
        color: $white;
        background: $blue;
        border-radius: 10px;
        &.CalendarDay__selected_start:before {
          content: " ";
          background: $light-blue;
          height: 20px;
          width: 16px;
          position: absolute;
          z-index: -1;
          margin-left: 14px;
          top: 0;
          left: 0;
        }
        &.CalendarDay__selected_end:before {
          content: " ";
          background: $light-blue;
          height: 20px;
          width: 16px;
          position: absolute;
          z-index: -1;
          margin-right: 14px;
          top: 0;
          right: 0;
        }
      }

      &.CalendarDay__selected_start.CalendarDay__selected_end:before {
        content: none;
      }

      &.CalendarDay:hover {
        background: #e4e7e7;

        &.CalendarDay__selected_start,
        &.CalendarDay__selected_end {
          background: $blue;
          border-radius: 10px;
        }
      }
    }

    &.CalendarDay:hover {
      border: none;
    }
  }

  .DayPicker_weekHeader {
    top: 38px;

    .DayPicker_weekHeader_ul {
      padding-top: 22px;
    }

    .DayPicker_weekHeader_li small {
      font-weight: 600;
      visibility: hidden;
      display: block;
      margin-left: 7px;
      color: $dark-gray;

      // This is probably really silly and we'll end up regretting it
      &:first-letter {
        visibility: visible;
      }
    }
  }

  .DayPicker_focusRegion {
    height: 260px;
  }

  .DayPickerNavigation_button__horizontal {
    top: 33px;
  }

  button.DayPickerNavigation_leftButton__horizontal {
    > svg {
      height: 19px;
      position: relative;
      right: 6px;
      @include rounded-arrow($dark-gray);
    }
  }

  button.DayPickerNavigation_rightButton__horizontal {
    > svg {
      height: 19px;
      position: relative;
      left: 7px;
      @include rounded-arrow($dark-gray);
    }
  }

  .datepicker-side-buttons {
    @extend .general-text;
    position: absolute;
    width: $datepicker-side-buttons-width;
    right: -144px;
    top: 0;
    bottom: 0;
    padding-top: 33px;
    padding-bottom: 20px;
    z-index: 1;

    .datepicker-preset-date {
      @include clickable();
      font-weight: 400;
      width: 126px;
      margin-bottom: 0;
      border-radius: 13px;
      padding: 2px 16px 3px;
      font-size: 14px;

      &:hover {
        background: lighten($blue, 25);
      }

      &.selected {
        background: $blue;
        color: $white;
      }

      &.gray {
        color: $light-gray;
        pointer-events: none;
      }
    }
  }
}
